import React from 'react';
import { CompactHeader as Header } from 'pages/components/compactHeader';
import { useLocaleContext } from 'context/LocaleContext';
import { useAuthenticationContext } from 'context/AuthenticationContext';
import isIreland from '../utils/isIreland';
import addLocaleToLink from '../utils/addLocaleToLink';
import selectAdvertisingPolicy from '../pages/submission/utils/selectAdvertisingPolicy/index';

const Layout = ({ children }) => {
	const { currentLocale } = useLocaleContext();

	const selectFAQLink = () => {
		if (isIreland()) {
			return 'ie/';
		}
		return addLocaleToLink(currentLocale);
	};

	const { jwt } = useAuthenticationContext();
	const hasCompanyId = Object.prototype.hasOwnProperty.call(
		jwt,
		'company_id'
	);

	return (
		<>
			<div>
				<Header />
				<div className="max-w-screen-xl px-6 py-6 mx-auto space-y-12 lg:px-12 xl:max-w-screen-2xl md:py-12 lg:space-y-4">
					{children}
				</div>

				<footer>
					<div className="bottom-0 p-8 pt-6">
						<div className="">
							<a
								href={`https://www.brighthr.com/${selectFAQLink()}help/faqs/exchange`}
								className="mr-4 font-bold text-blue-800"
								target="_blank"
								rel="noreferrer"
							>
								FAQs
							</a>
							<a
								href={selectAdvertisingPolicy(currentLocale)}
								className="mr-4 font-bold text-blue-800"
								target="_blank"
								rel="noreferrer"
							>
								Advertising policy
							</a>
							{hasCompanyId && (
								<a
									href="https://app.brighthr.com/dashboard"
									className="font-bold text-blue-800"
									target="_blank"
									rel="noreferrer"
								>
									Back to BrightHR
								</a>
							)}
						</div>
						<p className="mt-2 text-sm">
							Bright Exchange contains links to other websites.
							Please be aware that we are not responsible or
							liable for the privacy practices of other websites.
							We encourage you to be aware when you leave Bright
							Exchange and to read the privacy policies of each
							and every website that collects personally
							identifiable information.
						</p>
					</div>
				</footer>
			</div>
		</>
	);
};

export default Layout;
