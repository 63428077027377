import http from 'utils/http';
import config from 'utils/config';

const getBrightCompanyDetails = (companyId) => {
	const { COMPANY_API } = config();
	const uri = `${COMPANY_API}/${companyId}`;

	return http.get(uri).then(({ data }) => data);
};
export default getBrightCompanyDetails;
