import React from 'react';

const SubmissionFailed = ({ setCurrentStep }) => (
	<div className="border border-gray-300 rounded p-4 bg-white shadow-xl m-auto md:w-1/3 mt-10">
		<p>There was a problem submitting your advert.😞</p>
		<button
			type="button"
			className="bg-blue-300 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded m-2"
			onClick={async () => {
				setCurrentStep('preview');
			}}
		>
			Back
		</button>
	</div>
);

export default SubmissionFailed;
