import React from 'react';
import Icon from '@brighthr/component-icon';

const ScrollToTopButton = () => {
	const handleClick = () => window.scrollTo(0, 0);

	return (
		<div className="flex justify-end sticky bottom-0 z-50">
			<button
				type="button"
				onClick={handleClick}
				className="flex items-center justify-center rounded-3xl p-4 mb-8 -mr-4 bg-blue-700 opacity-60 hover:transform hover:scale-105 hover:opacity-100"
				aria-label="Scroll to top"
			>
				<Icon iconName="up-arrow" className="fill-white" size="sm" />
			</button>
		</div>
	);
};
export default ScrollToTopButton;
