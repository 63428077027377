import http from 'utils/http';
import config from 'utils/config';

const getCategories = (locale) => {
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/category`;

	return http.get(uri).then(({ data }) => data.data.allOfferCategories);
};

export default getCategories;
