import React, { useState, useEffect } from 'react';
import getCategories from 'services/getCategories';
import getRegions from 'services/getRegions';
import getGroups from 'services/getGroups';
import { useLocaleContext } from 'context/LocaleContext';
import { useViewportContext } from 'context/ViewportContext';
import { Groups } from 'domain/Groups';
import buildFilter from 'utils/filterBuilder';
import { useNavigate } from 'react-router-dom';
import SkeletonAdvert from './skeletonAdvert';
import AdvertFilter from './group/filter';
import Layout from '../../layouts';
import GroupCard from './groupCard';
import Spotlight from './spotlight';

const GroupedMarketplace = () => {
	const navigate = useNavigate();

	const [groups, setGroups] = useState<Groups[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [marketplaceFilter, setMarketplaceFilter] = useState('0');
	const [regionFilter, setRegionFilter] = useState('0');
	const [audienceFilter, setAudienceFilter] = useState('0');
	const [searchTerm, setSearchTerm] = useState('');
	const [userInput, setUserInput] = useState('');
	const [availableCategories, setAvailableCategories] = useState([]);
	const [availableRegions, setAvailableRegions] = useState([]);
	const [pageSize, setPageSize] = useState<number | undefined>(undefined);

	const { isLargeViewport } = useViewportContext();
	useEffect(() => {
		setPageSize(isLargeViewport ? 6 : 3);
	}, [isLargeViewport]);

	const { languageCode } = useLocaleContext();
	useEffect(() => {
		if (pageSize) {
			Promise.all([
				getCategories(languageCode),
				getRegions(languageCode),
				getGroups(languageCode, pageSize),
			])
				.then(([categories, regions, groupsData]) => {
					setAvailableCategories(categories);
					setAvailableRegions(regions);
					setGroups(groupsData);
					setIsLoading(false);
				})
				.catch((err) => {
					console.log('err', err);
				});
		}
	}, [languageCode, pageSize]);

	const hasSetFilters =
		marketplaceFilter !== '0' ||
		regionFilter !== '0' ||
		audienceFilter !== '0' ||
		searchTerm !== '';

	useEffect(() => {
		if (hasSetFilters) {
			const filters = buildFilter({
				selectedCategoryId: marketplaceFilter,
				selectedRegionId: regionFilter,
				selectedAudienceId: audienceFilter,
				searchTerm,
			});
			const allAdvertsUrl = `/group/59342879${filters}`;
			navigate(allAdvertsUrl);
		}
	}, [
		navigate,
		hasSetFilters,
		marketplaceFilter,
		regionFilter,
		audienceFilter,
		searchTerm,
	]);

	return (
		<>
			<Layout>
				<div className="space-y-4" data-testid="marketplace-view">
					<AdvertFilter
						isLoading={isLoading}
						userInput={userInput}
						setUserInput={setUserInput}
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
						setIsLoading={() => {}}
						availableCategories={availableCategories}
						availableRegions={availableRegions}
						selectedCategoryId={
							marketplaceFilter === '-1'
								? null
								: marketplaceFilter
						}
						selectedRegionId={
							regionFilter === '-1' ? null : regionFilter
						}
						selectedAudience={
							audienceFilter === '-1' ? null : audienceFilter
						}
						setMarketplaceFilter={setMarketplaceFilter}
						setRegionFilter={setRegionFilter}
						setAudienceFilter={setAudienceFilter}
						navigateToAllAdvertsWithFilters
					/>

					<div className="space-y-4 md:space-y-0">
						{isLoading && (
							<>
								<div className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-4">
									<SkeletonAdvert />
									<SkeletonAdvert />
									<SkeletonAdvert />
									{isLargeViewport && (
										<React.Fragment>
											<SkeletonAdvert />
											<SkeletonAdvert />
											<SkeletonAdvert />
										</React.Fragment>
									)}
								</div>
							</>
						)}
					</div>
				</div>
				{!isLoading && (
					<React.Fragment>
						<Spotlight
							audience={audienceFilter}
							categoryId={marketplaceFilter}
							locale={languageCode}
							regionId={regionFilter}
							searchTerm={searchTerm}
						/>
						{groups.map((group) => (
							<GroupCard key={group.id as number} group={group} />
						))}
					</React.Fragment>
				)}
			</Layout>
		</>
	);
};

export default GroupedMarketplace;
