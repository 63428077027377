import React, { useEffect } from 'react';
import AdvertSubmission from 'pages/submission';
import {
	Routes as ReactRouterRoutes,
	Route,
	useNavigate,
} from 'react-router-dom';
import Details from 'pages/show_details';
import AdminPortal from 'pages/admin_portal';
import GroupedMarketplace from 'pages/groups';
import SuccessfulSubmission from 'pages/submission/components/SubmissionSuccessful';
import Landing from 'pages/landing';
import useTelemetry from 'hooks/useTelemetry';
import isAdmin from 'utils/isAdmin';
import NotFound from 'pages/not_found';
import AdvertisingPolicy from 'pages/advertising_policy';
import AdvertisingPolicyAUNZ from 'pages/advertising_policy_au_nz';
import AdvertisingPolicyCA from 'pages/advertising_policy_ca';
import Logout from 'pages/logout';
import useHotJar from 'hooks/useHotJar';
import CompanyProfile from 'pages/companyProfile';
import gaPageView from 'utils/ga/pageview';
import Group from 'pages/groups/group';
import { useLocaleContext } from 'context/LocaleContext';
import useDrift from '../hooks/useDrift';

const Routes = () => {
	const { currentLocale } = useLocaleContext();

	useTelemetry(currentLocale.languageCode);
	useHotJar();
	useDrift();
	gaPageView();

	function Redirect({ to }) {
		const navigate = useNavigate();
		useEffect(() => {
			navigate(to);
		});
		return null;
	}

	const languages = ['en', 'ie', 'ca', 'au', 'nz'];

	return (
		<ReactRouterRoutes>
			<Route path="/" element={<Landing />} />

			{languages.map((language) => (
				<React.Fragment key={language}>
					<Route
						exact
						path={`/${language}/details/:id`}
						element={<Details />}
					/>
					<Route
						exact
						path={`/${language}/submission`}
						element={<AdvertSubmission />}
					/>
					<Route
						exact
						path={`/${language}/edit/:advertId`}
						element={<AdvertSubmission />}
					/>
					<Route
						exact
						path={`/${language}/submitted`}
						element={<SuccessfulSubmission />}
					/>
					<Route
						exact
						path={`/${language}/advertising_policy`}
						element={<AdvertisingPolicy />}
					/>
					<Route
						exact
						path={`/${language}/advertising_policy_au_nz`}
						element={<AdvertisingPolicyAUNZ />}
					/>
					<Route
						exact
						path={`/${language}/advertising_policy_CA`}
						element={<AdvertisingPolicyCA />}
					/>
					<Route
						exact
						path={`/${language}/logout`}
						element={<Logout />}
					/>
					<Route
						exact
						path={`/${language}/profile`}
						element={<CompanyProfile />}
					/>
					<Route path={`/${language}`} element={<Landing />} />
					<Route
						path={`/${language}/marketplace`}
						element={<Redirect to={`/${language}/groups`} />}
					/>
					<Route
						path={`/${language}/groups`}
						element={<GroupedMarketplace />}
					/>
					<Route
						exact
						path={`/${language}/group/:id`}
						element={<Group />}
					/>
					{isAdmin() && (
						<Route
							exact
							path={`/${language}/admin_portal`}
							element={<AdminPortal />}
						/>
					)}
				</React.Fragment>
			))}
			<Route path="/groups" element={<GroupedMarketplace />} />
			<Route exact path="/group/:id" element={<Group />} />
			<Route path="/marketplace" element={<Redirect to="/groups" />} />
			<Route exact path="/details/:id" element={<Details />} />
			<Route exact path="/submission" element={<AdvertSubmission />} />
			<Route
				exact
				path="/edit/:advertId"
				element={<AdvertSubmission />}
			/>
			<Route exact path="/submitted" element={<SuccessfulSubmission />} />
			{isAdmin() && (
				<Route exact path="/admin_portal" element={<AdminPortal />} />
			)}
			<Route
				exact
				path="/advertising_policy"
				element={<AdvertisingPolicy />}
			/>
			<Route
				exact
				path="/advertising_policy_au_nz"
				element={<AdvertisingPolicyAUNZ />}
			/>
			<Route
				exact
				path="/advertising_policy_CA"
				element={<AdvertisingPolicyCA />}
			/>
			<Route exact path="/logout" element={<Logout />} />
			<Route exact path="/profile" element={<CompanyProfile />} />

			<Route path="/" element={<Landing />} />
			<Route element={<NotFound />} />
		</ReactRouterRoutes>
	);
};

export default Routes;
