import http from 'utils/http';
import config from 'utils/config';

const recordInteraction = (payload, locale) => {
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/telemetry`;

	return http.post(uri, { data: payload }).then((data) => data);
};

export default recordInteraction;
