import React from 'react';
import { trackEvent } from 'utils/ga';

const CategorySelector = ({
	id,
	name,
	defaultText,
	availableCategories,
	defaultSelection,
	setMarketplaceFilter,
	setIsLoading,
	onChange = null,
}) => (
	<React.Fragment>
		<select
			id={id}
			data-testid="offer-category"
			name={name}
			className="px-2 py-3 border rounded"
			defaultValue={defaultSelection}
			onChange={
				onChange ||
				((e) => {
					if (setIsLoading) {
						setIsLoading(true);
					}
					if (setMarketplaceFilter) {
						setMarketplaceFilter(e.target.value);
					}
					trackEvent('marketplace', 'click category filter');
				})
			}
		>
			<option key="default" value="0">
				{defaultText}
			</option>

			{availableCategories.map((category) => (
				<option key={category.id} value={category.id}>
					{category.name}
				</option>
			))}
		</select>
	</React.Fragment>
);
export default CategorySelector;
