import { Advert } from 'domain/Advert';

const mapAdvert = (advert): Advert => ({
	...advert,
	offerHeroImage: advert.offerHeroImage
		? advert.offerHeroImage.url
		: '../placeholder_hero.png',
	advertiserLogo: advert.advertiserLogo
		? advert.advertiserLogo.url
		: '../placeholder_profile.png',
	offerCategory: advert.offerCategory.name,
});

export default mapAdvert;
