// import useUser from "bright-hooks/authentication/useUser";
import { useEffect } from 'react';

import useUserpilotEnabled from 'hooks/useUserpilotEnabled';
import getToken from 'utils/getToken';
import buildUserData from './buildUserData';

const USERPILOT_TOKEN_ID = 'NX-1a9e8f6d';
const USERPILOT_SCRIPT_SRC = 'https://js.userpilot.io/sdk/latest.js';

declare global {
	// eslint-disable-next-line no-unused-vars
	interface Window {
		// eslint-disable-next-line no-unused-vars
		userpilot: { identify: (userId: string, data: object) => void };
		userpilotSettings: { token: string };
	}
}

/**
 * Dynamically loads the Userpilot script if enabled.
 * You can force enable Userpilot on non-production environments by setting
 * the local storage key `userpilotEnabled`
 * to `true`
 *
 * @return {void} - no return value
 */
const useUserpilot = (): void => {
	const isUserpilotEnabled = useUserpilotEnabled();
	const user = getToken();
	useEffect(() => {
		if (!isUserpilotEnabled || !user) return;

		window.userpilotSettings = {
			token: USERPILOT_TOKEN_ID,
		};

		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.onload = () => {
			if (window.userpilot) {
				const userData = buildUserData();
				window.userpilot.identify(user.guid, userData);
			}
		};
		script.src = USERPILOT_SCRIPT_SRC;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [isUserpilotEnabled, user]);
};

export default useUserpilot;
