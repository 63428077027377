import React from 'react';

const SubmissionSuccessful = ({ advert, isEditMode }) => (
	<div className="flex justify-between max-w-screen-xl mx-auto bg-white lg:p-12">
		<div className="space-y-4">
			{isEditMode ? (
				<p>Thanks for updating your advert.</p>
			) : (
				<p>
					Thanks for your submission of a new advert. We&apos;re
					thrilled that you want to advertise on our platform!
				</p>
			)}
			<h1 className="text-2xl font-bold">What happens next?</h1>
			<p>
				Our team will review your advert and ensure it meets our
				advertising policy within 3 working days.
			</p>
			<p>
				An email will be sent to {advert ? advert.email : 'you'} when
				your advert is live.
			</p>
			<a
				href="/groups"
				className="inline-block px-4 py-2 font-bold text-white bg-blue-700 rounded hover:bg-blue-800"
			>
				Back to marketplace
			</a>
		</div>
	</div>
);
export default SubmissionSuccessful;
