import React, { useContext, createContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useLocaleContextValue from '../../hooks/useLocaleContextValue';
import { LocaleState } from './LocaleState';

const LocaleContext = createContext<LocaleState | undefined>(undefined);

const LocaleProvider = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const localeContextValue = useLocaleContextValue(location, navigate);
	return (
		<LocaleContext.Provider value={localeContextValue}>
			{children}
		</LocaleContext.Provider>
	);
};

const useLocaleContext = () => {
	const context = useContext(LocaleContext);
	if (!context)
		throw new Error('Locale context needs to be wrapped in provider');
	return context;
};

export { LocaleProvider, useLocaleContext };
