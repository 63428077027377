import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import getSpotlight from 'services/getSpotlight';
import { Advert } from '../../../domain/Advert';
import { Group } from '../../../domain/Groups';
import sliderSettings from './sliderSettings';

interface SpotlightAdvertProps {
	advert: Advert;
}

export interface SpotlightProps {
	audience?: string;
	categoryId?: string;
	groupId?: string;
	locale?: string;
	regionId?: string;
	searchTerm?: string;
	pageSize?: number;
}

const SpotlightAdvert = ({ advert }: SpotlightAdvertProps) => (
	<div className="flex flex-col flex-auto overflow-hidden bg-white border md:flex-row md:h-full xl:h-[unset] rounded-xl">
		<div className="relative w-full md:w-1/2 lg:w-2/3">
			<div className="absolute z-10 flex items-center px-3 space-x-2 text-white bg-gray-900 bg-opacity-50 rounded-full bottom-4 right-4 lg:bottom-6 lg:right-6">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					stroke="#fff"
					fill="none"
				>
					<path d="M0 0h24v24H0z" stroke="none" />
					<path d="M12 6l4 6 5-4-2 10H5L3 8l5 4z" />
				</svg>
				<span className="block text-xs leading-none tracking-widest uppercase">
					Spotlight
				</span>
			</div>
			<img
				className="object-cover w-full aspect-[16/9] md:h-full md:aspect-[unset] xl:h-[unset] xl:aspect-[16/9]"
				src={`${advert.offerHeroImage}?w=1024&h=1024`}
				alt={advert.advertiserName}
			/>
		</div>
		<div className="flex flex-col p-4 space-y-4 xl:p-8 md:w-1/2 lg:w-1/3">
			<div className="flex items-start space-x-4">
				<div className="flex-none bg-white border border-gray-300 rounded shadow-lg h-14 w-14">
					<img
						className="object-cover w-full h-full"
						src={`${advert.advertiserLogo}?fit=crop&h=60&w=60`}
						alt={advert.advertiserName}
					/>
				</div>
				<div className="flex-grow overflow-hidden">
					<h3 className="text-xl font-bold truncate xl:text-2xl">
						{advert.advertiserName}
					</h3>
					<p className="inline-block px-3 py-1 text-xs text-gray-500 bg-gray-100 rounded-full">
						{advert.offerCategory}
					</p>
				</div>
			</div>
			<p className="flex-auto pb-6 text-gray-500 xl:text-lg 2xl:text-xl">
				{advert.offerPromotionalText}
			</p>
			<div className="flex items-end justify-between space-x-8 overflow-hidden">
				{advert.isPercentageDiscount &&
					advert.discountText.length !== 0 && (
						<div className="flex flex-col justify-end flex-auto text-right">
							<p className="leading-none text-gray-500 xl:text-2xl">
								Save up to
							</p>
							<p className="flex-auto text-4xl font-bold truncate">
								{advert.discountText}
								<span>%</span>
							</p>
						</div>
					)}

				{!advert.isPercentageDiscount && (
					<p className="flex-auto text-xl font-bold text-right truncate xl:text-3xl">
						{advert.discountText}
					</p>
				)}
			</div>
		</div>
	</div>
);

const Spotlight = ({
	audience = '0',
	categoryId = '0',
	groupId = '49566895',
	locale = 'en-gb',
	regionId = '0',
	searchTerm = '',
	pageSize = 12,
}: SpotlightProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [spotlights, setSpotlights] = useState<Group>({
		name: '',
		filterCount: { count: 0 },
		adverts: [],
	});
	const [spotlightAdverts, setSpotlightAdverts] = useState<number>(0);
	useEffect(() => {
		getSpotlight({
			audience,
			categoryId,
			groupId,
			locale,
			regionId,
			searchTerm,
			pageSize,
		})
			.then((adverts) => {
				setSpotlights(adverts);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log('err', err);
			});
	}, [audience, categoryId, groupId, locale, regionId, searchTerm, pageSize]);

	useEffect(() => {
		setSpotlightAdverts(spotlights.adverts?.length);
	}, [spotlights]);

	return (
		<React.Fragment>
			{spotlightAdverts > 0 && (
				<h2 className="mt-8 mb-4 text-3xl font-bold">
					Spotlight Offers
				</h2>
			)}

			<Slider {...sliderSettings(isLoading, spotlightAdverts)}>
				{spotlights.adverts?.map((spotlightAdvert) => (
					<Link
						key={spotlightAdvert.id}
						className="flex flex-col h-full px-2"
						to={{
							pathname: `/details/${spotlightAdvert.id}`,
						}}
						onClick={() => {}}
					>
						<SpotlightAdvert
							key={spotlightAdvert.id}
							advert={spotlightAdvert}
						/>
					</Link>
				))}
			</Slider>
		</React.Fragment>
	);
};

export default Spotlight;
