import React from 'react';
import { Link } from 'react-router-dom';
import { trackEvent } from 'utils/ga';
import OfferCard from '../offerCard';
import { GroupSummary } from '../../../domain/Groups';
import GroupNames from '../constants';

interface Props {
	group: GroupSummary;
}

const GroupCard = ({ group }: Props) => (
	<React.Fragment>
		{group.data.adverts.length > 0 && (
			<div>
				<h2 className="mt-8 mb-4 text-3xl font-bold">{group.name}</h2>
				<div className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 lg:grid-cols-3 gap-7">
					{group.data.adverts.map((offer) => (
						<Link
							key={offer.id}
							className="flex flex-col"
							to={{
								pathname: `/details/${offer.id}`,
							}}
							onClick={() => {
								trackEvent(
									'marketplace',
									'click standard card'
								);
							}}
						>
							<div className="h-full duration-100 transform hover:shadow-lg transition-transform-shadow hover:-translate-y-1">
								<OfferCard advert={offer} />
							</div>
						</Link>
					))}
				</div>
				<div className="flex items-center justify-center mt-4 ">
					<span className="flex-1 invisible w-1/3 h-px border-b border-gray-300 xl:visible" />

					<div className="flex items-center justify-center h-12 ml-2 mr-2 p-2 font-bold text-blue-800 border-2 border-gray-300 rounded-full cursor-pointer xl:w-1/4">
						<Link to={`/group/${group.id}`}>
							{`See ${
								group.name !== GroupNames.AllAdverts
									? 'all '
									: ''
							}${group.name}`}
						</Link>
					</div>
					<span className="flex-1 invisible w-1/3 h-px border-b border-gray-300 xl:visible" />
				</div>
			</div>
		)}
	</React.Fragment>
);

export default GroupCard;
