import getConfig from './config';

const mapping = {
	'sandbox-exchange.brighthr.com': 'sandbox',
	'exchange.brighthr.com': 'prod',
	'qa-exchange.brighthr.com': 'qa',
	localhost: 'dev',
};

const environment = mapping[window.location.hostname] || 'sandbox';

const Config = () => ({
	BUILD_VERSION: process.env.BUILD_VERSION,
	environment,
	...getConfig(environment),
});

export default Config;
