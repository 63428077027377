import React from 'react';
import LocaleSelector from 'pages/components/localeSelector';
import LocaleModal from 'pages/components/localeModal';

import { useLocaleContext } from 'context/LocaleContext';
import ExLogo from './exchange-logo.svg';
import BrightLogo from './bright-logo.svg';
import HeroImageWebP from './images/lander_hero_new.webp';
import HeroImageWebP2x from './images/lander_hero_new@2x.webp';
import HeroImagePNG from './images/lander_hero_new.png';
import HeroImagePNG2x from './images/lander_hero_new@2x.png';
import HeroDevice from './images/BrightExDevice.png';
import HeroDevice2x from './images/BrightExDevice@2x.png';
import CreateAdvert from './images/hiw_create-advert.png';
import CreateAdvertForm from './images/hiw_create-advert-form.png';
import ApproveAdvert from './images/hiw_approve-advert.png';
import ApproveAdvertCheck from './images/hiw_approve-advert-check.png';
import PublishAdvert from './images/hiw_publish-advert.png';
import PublishAdvertOverlay from './images/hiw_publish-advert-overlay.png';
import EditImage from './images/EditOptions.png';
import addLocaleToLink from '../../utils/addLocaleToLink';

const Landing = () => {
	const { currentLocale } = useLocaleContext();

	const selectBusinessNumber = () => {
		if (
			currentLocale.countryCode === 'nz' ||
			currentLocale.countryCode === 'ca'
		)
			return 'thousands of';
		return '100,000+';
	};

	const consumersString = () => {
		if (
			currentLocale.countryCode === 'nz' ||
			currentLocale.countryCode === 'ca'
		) {
			return 'and';
		}
		return 'and over 1 million';
	};

	const copyEnd = () => {
		if (
			currentLocale.countryCode === 'nz' ||
			currentLocale.countryCode === 'ca'
		) {
			return '';
		}
		return ' globally';
	};

	return (
		<>
			<div className="max-w-full overflow-x-hidden ">
				<div className="absolute right-0">
					<LocaleModal />
					<LocaleSelector isLanding />
				</div>
				<div className="py-8 md:py-16">
					<header className="relative z-40 flex items-center justify-between max-w-screen-xl px-6 mx-auto mb-16">
						<div className="w-40 text-blue-700 sm:w-48 md:w-56">
							<img src={ExLogo} alt="Bright Exchange" />
						</div>
						<div className="flex justify-center">
							<a
								href="/groups"
								className="flex items-center px-4 ml-4 space-x-2 text-blue-700 rounded-full md:text-white md:py-3 md:px-8 md:bg-blue-700"
							>
								<svg
									className="fill-current"
									width="13"
									height="13"
									viewBox="0 0 13 13"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M11.9375 7.125H10.4375V3.5625C10.4375 3.17188 10.3633 2.80859 10.2148 2.47266C10.0664 2.12891 9.86328 1.83203 9.60547 1.58203C9.35547 1.32422 9.05859 1.12109 8.71484 0.972656C8.37891 0.824219 8.01562 0.75 7.625 0.75H5.375C4.98438 0.75 4.62109 0.824219 4.28516 0.972656C3.94141 1.12109 3.64062 1.32422 3.38281 1.58203C3.13281 1.83203 2.93359 2.12891 2.78516 2.47266C2.63672 2.80859 2.5625 3.17188 2.5625 3.5625V7.125H1.0625C0.90625 7.125 0.773438 7.17969 0.664062 7.28906C0.554688 7.39844 0.5 7.53125 0.5 7.6875V12.1875C0.5 12.3438 0.554688 12.4766 0.664062 12.5859C0.773438 12.6953 0.90625 12.75 1.0625 12.75H11.9375C12.0938 12.75 12.2266 12.6953 12.3359 12.5859C12.4453 12.4766 12.5 12.3438 12.5 12.1875V7.6875C12.5 7.53125 12.4453 7.39844 12.3359 7.28906C12.2266 7.17969 12.0938 7.125 11.9375 7.125ZM3.6875 3.5625C3.6875 3.09375 3.85156 2.69531 4.17969 2.36719C4.50781 2.03906 4.90625 1.875 5.375 1.875H7.625C8.09375 1.875 8.49219 2.03906 8.82031 2.36719C9.14844 2.69531 9.3125 3.09375 9.3125 3.5625V7.125H3.6875V3.5625ZM11.375 11.625H1.625V8.25H11.375V11.625ZM7.0625 9.9375C7.0625 10.0938 7.00781 10.2266 6.89844 10.3359C6.78906 10.4453 6.65625 10.5 6.5 10.5C6.34375 10.5 6.21094 10.4453 6.10156 10.3359C5.99219 10.2266 5.9375 10.0938 5.9375 9.9375C5.9375 9.78125 5.99219 9.64844 6.10156 9.53906C6.21094 9.42969 6.34375 9.375 6.5 9.375C6.65625 9.375 6.78906 9.42969 6.89844 9.53906C7.00781 9.64844 7.0625 9.78125 7.0625 9.9375Z" />
								</svg>
								<p className="font-bold">Log in</p>
							</a>
						</div>
					</header>
					<div className="relative z-30 flex flex-col flex-wrap items-center max-w-screen-xl mx-auto space-y-12 overflow-visible lg:flex-row lg:justify-between md:space-y-24 lg:space-y-0">
						<div className="z-10 w-full px-6 space-y-8 lg:pr-20 lg:space-y-6 lg:w-1/2 xl:w-5/12">
							<h1 className="text-3xl font-bold leading-tight md:text-4xl lg:text-5xl">
								<span className="text-blue-700">
									Get more business
								</span>{' '}
								through Bright Exchange.
							</h1>
							<p className="md:text-lg">
								{`Becoming a Bright Exchange user could put your brand in front of ${selectBusinessNumber()} business owners ${consumersString()} potential consumers${copyEnd()}.`}
							</p>
							<p className="md:text-lg">
								Use Bright Exchange to advertise your business
								and list offers, and work with our team to
								co-market and sell together.
							</p>

							<ul className="relative pt-2 mb-4 list-inside tick-list last:mb-0">
								<li className="relative pl-8 mb-2 md:text-lg">
									<svg
										className="absolute top-0 left-0 inline-block w-6 h-6 mr-2"
										viewBox="0 0 24 24"
									>
										<title>check</title>
										<path
											fill="#E10078"
											d="M19.293 5.293l-10.293 10.293-4.293-4.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5 5c0.391 0.391 1.024 0.391 1.414 0l11-11c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0z"
										/>
									</svg>
									Become part of an
									<span className="font-bold">
										{' '}
										exclusive community{' '}
									</span>{' '}
									of like-minded business owners.
								</li>
								<li className="relative pl-8 mb-2 md:text-lg">
									<svg
										className="absolute top-0 left-0 inline-block w-6 h-6 mr-2"
										viewBox="0 0 24 24"
									>
										<title>check</title>
										<path
											fill="#E10078"
											d="M19.293 5.293l-10.293 10.293-4.293-4.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5 5c0.391 0.391 1.024 0.391 1.414 0l11-11c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0z"
										/>
									</svg>
									Get insights into how your advert is
									performing so you can effectively
									<span className="font-bold">
										{' '}
										promote your brand.
									</span>
								</li>
								<li className="relative pl-8 mb-2 md:text-lg">
									<svg
										className="absolute top-0 left-0 inline-block w-6 h-6 mr-2"
										viewBox="0 0 24 24"
									>
										<title>check</title>
										<path
											fill="#E10078"
											d="M19.293 5.293l-10.293 10.293-4.293-4.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5 5c0.391 0.391 1.024 0.391 1.414 0l11-11c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0z"
										/>
									</svg>
									Bright Exchange is
									<span className="font-bold">
										{' '}
										100% free,
									</span>{' '}
									just login with your BrightHR account.
								</li>
							</ul>
							<p className="md:text-lg">
								Plus, as Bright Exchange doubles up as an
								employee perk platform, staff get access to
								exclusive money-saving deals. Keep your best
								people and attract top talent with your own FREE
								staff discount platform.
							</p>
							<div className="space-y-4 md:flex md:flex-row md:space-y-0 md:space-x-4">
								<p>
									Not a BrightHR Customer? Ask us about{' '}
									<span className="font-bold">
										Bright Exchange
									</span>
								</p>
								<a
									href={`https://www.brighthr.com/${addLocaleToLink(
										currentLocale
									)}book-a-demo/exchange/`}
									className="block px-4 py-3 text-center text-white bg-pink-500 rounded-full md:inline-block md:px-8 hover:bg-pink-700 md:flex-none"
								>
									Book a demo
								</a>
							</div>
						</div>
						<div className="relative flex w-full md:w-3/4 lg:w-1/2 xl:w-7/12">
							<picture className="absolute top-0 z-0 transform -translate-y-24 md:-translate-y-44 ">
								<img
									loading="eager"
									srcSet={`${HeroDevice}, ${HeroDevice2x} 2x`}
									src={HeroDevice}
									alt="Bright Device"
									width="990"
									height="990"
								/>
							</picture>
							<picture className="z-10 w-full ml-auto lg:transform lg:translate-x-16">
								<source
									srcSet={`${HeroImageWebP}, ${HeroImageWebP2x} 2x`}
									type="image/webp"
									media="(min-width: 2000px)"
								/>
								<source
									srcSet={`${HeroImageWebP}, ${HeroImageWebP2x} 2x`}
									type="image/webp"
								/>
								<source
									srcSet={`${HeroImagePNG}, ${HeroImagePNG2x} 2x`}
									type="image/png"
									media="(min-width: 2000px)"
								/>
								<source
									srcSet={`${HeroImagePNG}, ${HeroImagePNG2x} 2x`}
									type="image/png"
								/>
								<img
									loading="eager"
									src={HeroImagePNG}
									alt="Bright Exchange"
									className="w-full ml-auto"
								/>
							</picture>
						</div>
					</div>
				</div>
				<div className="py-16">
					<div className="max-w-screen-xl px-8 mx-auto ">
						<div className="mb-20 space-y-6 ">
							<h1 className="text-3xl text-center lg:text-4xl">
								How it works
							</h1>
							<div>
								<ol className="flex flex-col items-center space-y-12 lg:space-y-0 lg:flex-row lg:flex-wrap lg:items-start lg:justify-between">
									<li className="flex flex-col w-full px-8 space-y-3 md:w-3/4 lg:w-1/3 lg:px-12">
										<div className="relative">
											<div className="absolute top-0 right-0 z-10 p-3 transform translate-x-5 translate-y-5 bg-white rounded-lg shadow-lg">
												<span className="font-bold text-red-600 sm:text-2xl lg:text-base">
													10% off
												</span>
											</div>
											<img
												src={CreateAdvertForm}
												className="absolute bottom-0 left-0 z-10 w-24 transform -translate-x-6 -translate-y-3 rounded-lg shadow-lg xl:top-0 sm:w-40 lg:w-24 xl:w-auto sm:-translate-y-8 xl:-translate-y-12"
												alt="Create Advert Form"
											/>
											<img
												src={CreateAdvert}
												className="object-cover w-full h-full rounded-lg"
												alt="Create Advert"
											/>
										</div>
										<h3 className="text-xl font-bold text-blue-700 lg:text-2xl">
											1. Create advert
										</h3>
										<p className="text-gray-700">
											Create your advert by adding the
											details needed to redeem your offer
											and an image of your product.
										</p>
									</li>
									<li className="flex flex-col w-full px-8 space-y-3 md:w-3/4 lg:w-1/3 lg:px-12 lg:transform lg:translate-y-8">
										<div className="relative">
											<div className="absolute bottom-0 left-0 z-10 p-3 transform -translate-x-5 -translate-y-5 bg-white rounded-lg shadow-lg">
												<img
													src={ExLogo}
													className="w-24 sm:w-32 lg:w-24"
													alt="BrightExchange"
												/>
											</div>
											<img
												src={EditImage}
												className="absolute right-0 z-10 w-40 transform translate-x-5 translate-y-5 rounded-lg shadow-lg -top-10"
												alt="Edit Advert"
											/>
											<img
												src={ApproveAdvertCheck}
												className="absolute right-0 z-10 transform translate-x-5 translate-y-5 rounded-full shadow-lg top-24"
												alt="Check Advert"
											/>
											<img
												src={ApproveAdvert}
												className="object-cover w-full h-full rounded-lg"
												alt="Approve Advert"
											/>
										</div>
										<h3 className="text-xl font-bold text-blue-700 lg:text-2xl">
											2. Submit for approval
										</h3>
										<p className="text-gray-700">
											Once you’re happy, submit your offer
											for approval. And don&apos;t worry,
											if you want to make edits after
											it&apos;s published, you can.
										</p>
									</li>
									<li className="flex flex-col w-full px-8 space-y-3 md:w-3/4 lg:w-1/3 lg:px-12 lg:transform lg:translate-y-16">
										<div className="relative">
											<img
												src={PublishAdvertOverlay}
												className="absolute top-0 right-0 z-10 w-3/4 transform translate-x-16 md:w-auto md:translate-x-24 lg:translate-x-20 lg:-translate-y-16"
												alt="Publish Advert Overlay"
											/>
											<img
												src={PublishAdvert}
												className="object-cover w-full h-full rounded-lg"
												alt="Publish Advert"
											/>
										</div>
										<h3 className="text-xl font-bold text-blue-700 lg:text-2xl">
											3. Advert is published
										</h3>
										<p className="text-gray-700">
											Our team of moderators will
											carefully check your ad before
											publishing it to the Marketplace.
										</p>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div className="py-16 bg-blue-700">
					<div className="max-w-screen-xl px-8 mx-auto space-y-12 text-center">
						<div className="flex flex-col items-center space-y-6 text-white">
							<h1 className="text-3xl font-bold lg:text-4xl">
								Not a BrightHR Customer? Ask us about Bright
								Exchange.
							</h1>
							<a
								className="w-full px-8 py-3 text-center text-white bg-pink-500 rounded-full md:w-auto"
								href={`https://www.brighthr.com/${addLocaleToLink(
									currentLocale
								)}book-a-demo/exchange/`}
							>
								Book a demo
							</a>
							<p>Already have a BrightHR account? </p>
							<a
								href="/groups"
								className="flex items-center justify-center w-full px-8 py-3 space-x-2 text-white bg-transparent border border-white rounded-full md:w-auto"
							>
								<svg
									className="current-fill"
									width="13"
									height="13"
									viewBox="0 0 13 13"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.9375 7.125H10.4375V3.5625C10.4375 3.17188 10.3633 2.80859 10.2148 2.47266C10.0664 2.12891 9.86328 1.83203 9.60547 1.58203C9.35547 1.32422 9.05859 1.12109 8.71484 0.972656C8.37891 0.824219 8.01562 0.75 7.625 0.75H5.375C4.98438 0.75 4.62109 0.824219 4.28516 0.972656C3.94141 1.12109 3.64062 1.32422 3.38281 1.58203C3.13281 1.83203 2.93359 2.12891 2.78516 2.47266C2.63672 2.80859 2.5625 3.17188 2.5625 3.5625V7.125H1.0625C0.90625 7.125 0.773438 7.17969 0.664062 7.28906C0.554688 7.39844 0.5 7.53125 0.5 7.6875V12.1875C0.5 12.3438 0.554688 12.4766 0.664062 12.5859C0.773438 12.6953 0.90625 12.75 1.0625 12.75H11.9375C12.0938 12.75 12.2266 12.6953 12.3359 12.5859C12.4453 12.4766 12.5 12.3438 12.5 12.1875V7.6875C12.5 7.53125 12.4453 7.39844 12.3359 7.28906C12.2266 7.17969 12.0938 7.125 11.9375 7.125ZM3.6875 3.5625C3.6875 3.09375 3.85156 2.69531 4.17969 2.36719C4.50781 2.03906 4.90625 1.875 5.375 1.875H7.625C8.09375 1.875 8.49219 2.03906 8.82031 2.36719C9.14844 2.69531 9.3125 3.09375 9.3125 3.5625V7.125H3.6875V3.5625ZM11.375 11.625H1.625V8.25H11.375V11.625ZM7.0625 9.9375C7.0625 10.0938 7.00781 10.2266 6.89844 10.3359C6.78906 10.4453 6.65625 10.5 6.5 10.5C6.34375 10.5 6.21094 10.4453 6.10156 10.3359C5.99219 10.2266 5.9375 10.0938 5.9375 9.9375C5.9375 9.78125 5.99219 9.64844 6.10156 9.53906C6.21094 9.42969 6.34375 9.375 6.5 9.375C6.65625 9.375 6.78906 9.42969 6.89844 9.53906C7.00781 9.64844 7.0625 9.78125 7.0625 9.9375Z"
										fill="#ffffff"
									/>
								</svg>
								<p className="font-bold">Log in now</p>
							</a>
						</div>
					</div>
				</div>

				<div className="py-12">
					<div className="flex flex-col items-center justify-center max-w-screen-xl px-8 mx-auto space-y-12 text-center text-gray-900 lg:flex-row lg:space-y-0">
						<img
							src={ExLogo}
							className="inline-block w-40 fill-current lg:mr-12"
							alt="Bright Exchange"
						/>
						<p className="order-last lg:order-none">
							&copy; 2022. Bright Exchange
						</p>
						<img
							className="inline-block w-40 lg:ml-auto"
							src={BrightLogo}
							alt="BrightHR"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Landing;
