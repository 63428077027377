import BrightSession from 'utils/brightSession';
import config from 'utils/config';

function getHeaders() {
	const token = window.localStorage.getItem('userToken');
	const { BUILD_VERSION: BuildVersion } = config();

	return {
		...(token && {
			authorization: `bearer ${token}`,
		}),
		BrightClient: 'BrightExchange',
		BrightSession,
		...(BuildVersion && { BuildVersion }),
	};
}

export default getHeaders;
