import React from 'react';

export type CAFlagProps = React.SVGAttributes<SVGElement> & {
	className?: string;
};

export const CAFlag = ({ className }: CAFlagProps): React.ReactElement => (
	<svg
		width="32"
		height="24"
		viewBox="0 0 64 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		aria-hidden="true"
	>
		<path d="M64 0H0V48H64V0Z" fill="#BF1731" />
		<path d="M50 0H14V48H50V0Z" fill="white" />
		<path
			d="M20.55 23.7401L19.23 24.1901L25.37 29.5801C25.83 30.9601 25.21 31.3701 24.81 32.1001L31.47 31.2501L31.3 37.9501L32.68 37.9101L32.38 31.2601L39.05 32.0501C38.64 31.1801 38.27 30.7201 38.65 29.3201L44.78 24.2201L43.71 23.8301C42.83 23.1501 44.09 20.5701 44.28 18.9401C44.28 18.9401 40.7 20.1701 40.46 19.5301L39.55 17.7801L36.29 21.3601C35.93 21.4501 35.78 21.3001 35.7 21.0001L37.2 13.5201L34.82 14.8601C34.62 14.9501 34.42 14.8701 34.29 14.6401L32 10.0401L29.64 14.8201C29.46 14.9901 29.28 15.0101 29.14 14.8901L26.87 13.6201L28.23 21.0401C28.12 21.3301 27.86 21.4201 27.56 21.2601L24.45 17.7201C24.04 18.3701 23.77 19.4401 23.23 19.6801C22.69 19.9001 20.89 19.2301 19.68 18.9601C20.07 20.4601 21.36 22.9301 20.55 23.7401Z"
			fill="#BF1731"
		/>
	</svg>
);

export default CAFlag;
