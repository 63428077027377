import React, { useEffect, useState } from 'react';

import Alert from '@brighthr/component-alert';
import Icon from '@brighthr/component-icon';
import { Modal, ModalBody, ModalFooter } from '@brighthr/component-modal';
import Spinner from '@brighthr/component-spinner';

import { useLocaleContext } from 'context/LocaleContext';
import { CompactHeader as Header } from 'pages/components/compactHeader';

import { trackEvent } from 'utils/ga';
import getMyAdverts from '../../services/getMyAdverts';
import archiveOffer from '../../services/archiveOffer';
import NoAdverts from '../../styles/images/no_adverts.svg';
import NoCurrentAdverts from './NoCurrentAdverts';
import PreviousAdvertRow from './PreviousAdvertRow';
import CurrentAdvertRow from './CurrentAdvertRow';

const AdminPortal = () => {
	const [myAdverts, setMyAdverts] = useState([]);
	const [openArchiveModal, setOpenArchiveModal] = useState(false);
	const [success, setSuccess] = useState(false);
	const [showArchiveLoader, setShowArchiveLoader] = useState(false);
	const [selectedOfferId, setSelectedOfferId] = useState(0);
	const [refreshData, setRefreshData] = useState(false);
	const [screenWidth, setScreenWidth] = useState(0);
	const [expanded, setExpanded] = useState(false);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [archiveModalError, setArchiveModalError] = useState(false);
	const [mobileViewOn, setMobileViewOn] = useState(false);
	const [activeId, setActiveId] = useState(0);

	const { languageCode } = useLocaleContext();

	useEffect(() => {
		window.scrollTo(0, 0);
		getMyAdverts(languageCode)
			.then((res) => {
				setMyAdverts(res);
				setRefreshData(false);
				setScreenWidth(window.innerWidth);
				setLoading(false);
			})
			.catch(() => {
				setError(true);
			});
	}, [languageCode, refreshData]);

	if (loading)
		return (
			<div className="flex justify-center mt-24">
				<Spinner
					size="base"
					className="stroke-black"
					ariaLabel="loading"
				/>
			</div>
		);

	const handleArchive = (id) => {
		setSelectedOfferId(id);
		setOpenArchiveModal(true);
	};

	const putArchivedOffer = (offerId) => {
		archiveOffer(offerId, languageCode)
			.then(() => {
				setSuccess(true);
				setShowArchiveLoader(false);
				setSelectedOfferId(0);
			})
			.catch(() => {
				setShowArchiveLoader(false);
				setArchiveModalError(true);
			});
	};

	const handleView = (id) => {
		setScreenWidth(window.innerWidth);
		if (screenWidth < 1024) {
			setActiveId(id);
			setExpanded(!expanded);
			setMobileViewOn(!mobileViewOn);
		}
	};

	const cellValue = ' hidden lg:flex flex w-1/6 mr-2 items-center';
	const cellValueButtons = 'hidden lg:flex flex w-64 mr-2 items-center';

	const cellValueNarrow =
		'hidden lg:flex flex w-1/12 mr-2 -ml-2 items-center';

	return (
		<>
			<Header />
			<div>
				<div className="max-w-screen-xl px-6 py-12 mx-auto space-y-12 lg:px-12">
					{error && (
						<Alert fullWidth type="error">
							<p>
								Sorry, an error has occurred. Please try again.
							</p>
						</Alert>
					)}
					<Alert type="information" fullWidth>
						The admin portal is where you can see how well your
						advert is performing in the marketplace.
					</Alert>
					{myAdverts.current.length === 0 &&
						myAdverts.previous.length === 0 && (
							<div className="flex flex-col items-center justify-center">
								<img
									className="mb-12 w-72"
									src={NoAdverts}
									alt="alt"
								/>
								<p className="text-lg text-center lg:w-2/5">
									You don&apos;t have any adverts yet. Create
									one by clicking the &apos;New Advert&apos;
									button above.
								</p>
							</div>
						)}
					{(myAdverts.current.length > 0 ||
						myAdverts.previous.length > 0) && (
						<>
							<div className="space-y-4">
								<h1 className="font-bold">
									Current advert
									{myAdverts.current.length === 1 ? '' : 's'}
								</h1>

								<div className="bg-white border-2 border-gray-300 rounded-md">
									{myAdverts.current.length === 0 ? (
										<NoCurrentAdverts />
									) : (
										<>
											<div className="hidden font-bold lg:flex lg:p-3">
												<span className="flex items-center mr-4 w-80">
													Promotion
												</span>
												<span className={cellValue}>
													Submitted
												</span>
												<span
													className={`${cellValue} -ml-2`}
												>
													Ends
												</span>
												<span
													className={cellValueNarrow}
												>
													Views
												</span>
												<span className={cellValue}>
													Status
												</span>
												<span
													className={cellValueButtons}
												/>
											</div>
											{myAdverts.current.map((advert) => (
												<CurrentAdvertRow
													advert={advert}
													expanded={expanded}
													handleView={handleView}
													handleArchive={
														handleArchive
													}
													activeId={activeId}
													mobileViewOn={mobileViewOn}
												/>
											))}
										</>
									)}
								</div>
							</div>

							<div className="space-y-4">
								<h1 className="font-bold">
									Previous advert
									{myAdverts.previous.length === 1 ? '' : 's'}
								</h1>

								<div className="bg-white border-2 border-gray-300 rounded-md">
									{myAdverts.previous.length === 0 ? (
										<div className="flex items-center justify-center p-12">
											You have no previous adverts
										</div>
									) : (
										<>
											<div className="hidden font-bold lg:flex lg:p-3">
												<span className="flex items-center mr-4 w-72">
													Promotion
												</span>
												<span className={cellValue}>
													Submitted
												</span>
												<span className={cellValue}>
													Expired
												</span>
												<span
													className={cellValueNarrow}
												>
													Views
												</span>
												<span className={cellValue}>
													Status
												</span>
												<span className={cellValue} />
											</div>
											{myAdverts.previous.map(
												(advert) => (
													<PreviousAdvertRow
														advert={advert}
														expanded={expanded}
														handleView={handleView}
														activeId={activeId}
														mobileViewOn={
															mobileViewOn
														}
													/>
												)
											)}
										</>
									)}
								</div>
							</div>
							{openArchiveModal && (
								<Modal
									title="Archive advert"
									color="inverted"
									close={() => {
										setOpenArchiveModal(false);
										setArchiveModalError(false);
									}}
								>
									<>
										{!success && !showArchiveLoader && (
											<>
												<ModalBody>
													This will add your advert to
													the archive and remove it
													from our marketplace. The
													details will still be
													available to view.
												</ModalBody>
												<ModalFooter>
													<button
														className="p-2 text-lg font-bold text-gray-500 bg-white border-2 border-gray-400 rounded-md cursor-pointer w-52 md:w-32"
														type="button"
														onClick={() => {
															trackEvent(
																'Admin Portal',
																'click archive cancel'
															);
															setOpenArchiveModal(
																false
															);
														}}
													>
														Cancel
													</button>
													<button
														className="flex items-center justify-center p-2 mb-1 text-lg font-bold text-white bg-blue-800 rounded-md cursor-pointer active:transform active:scale-105 md:mb-0 w-52 md:w-32"
														type="button"
														onClick={() => {
															trackEvent(
																'Admin Portal',
																'click archive ok'
															);
															putArchivedOffer(
																selectedOfferId
															);
															setShowArchiveLoader(
																true
															);
														}}
													>
														<div className="flex items-center justify-between w-1/4 md:w-3/4 md:justify-evenly">
															<Icon
																className="fill-white"
																iconName="tick-thin"
																size="sm"
															/>
															OK
														</div>
													</button>
												</ModalFooter>
											</>
										)}
										{showArchiveLoader && (
											<div className="flex items-center justify-center mb-4">
												<Spinner
													size="base"
													className="stroke-black"
													ariaLabel="archiving offer"
												/>
											</div>
										)}
										{success && (
											<div className="flex flex-col items-center justify-center mb-4">
												<p className="my-6">
													Your advert has been
													archived successfully.
												</p>
												<button
													className="p-2 text-lg font-bold text-white bg-blue-800 rounded-md cursor-pointer w-52 md:w-32"
													type="button"
													onClick={() => {
														setSuccess(false);
														setOpenArchiveModal(
															false
														);
														setRefreshData(true);
														setLoading(true);
													}}
												>
													OK
												</button>
											</div>
										)}

										{archiveModalError && (
											<Alert
												className="items-center"
												type="error"
												icon="error"
												fullWidth
											>
												There was a problem archiving
												this advert. Please try again.
											</Alert>
										)}
									</>
								</Modal>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};
export default AdminPortal;
