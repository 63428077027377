import React from 'react';
import { lightFormat, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { trackEvent } from 'utils/ga';
import Icon from '@brighthr/component-icon';

const cellValue = 'hidden lg:flex flex w-1/6 mr-3 px-2 items-center';
const cellValueNarrow = 'hidden lg:flex flex w-1/12 px-2 items-center';

const choosePathname = (id) => {
	const screenWidth = window.innerWidth;
	return screenWidth < 1024 ? '' : `/details/${id}`;
};

const PreviousAdvertRow = ({
	advert,
	handleView,
	activeId,
	expanded,
	mobileViewOn,
}) => (
	<>
		<Link
			key={advert.id}
			to={{
				pathname: choosePathname(advert.id),
			}}
			onClick={() => {
				trackEvent('Admin Portal', 'click view previous advert');
				handleView(advert.id);
			}}
			className="flex justify-between px-2 py-4 border-t border-gray-300 lg:p-3 odd:bg-gray-50"
		>
			<>
				<span className="flex items-center mr-4 w-72 line-clamp-1">
					{advert.offer_promotional_text}
				</span>
				<div className="flex items-center">
					{advert.offer_archived ? (
						<p className="flex items-center px-3 py-1 text-xs tracking-wider text-gray-600 bg-gray-700 lg:hidden bg-opacity-5 rounded-2xl">
							ARCHIVED
						</p>
					) : (
						<p className="flex items-center px-3 py-1 text-xs tracking-wider text-gray-600 bg-indigo-600 lg:hidden bg-opacity-10 rounded-2xl">
							EXPIRED
						</p>
					)}
					<span className="lg:hidden">
						{activeId === advert.id && expanded ? (
							<Icon iconName="chevron-thick-up" size="sm" />
						) : (
							<Icon iconName="chevron-thick-down" size="sm" />
						)}
					</span>
				</div>
			</>
			<span className={cellValue}>
				{lightFormat(parseISO(advert.createdAt), 'dd/MM/yyyy')}
			</span>
			<span className={cellValue}>
				{advert.offer_valid_until === '9999-12-31' ? (
					<span className="text-gray-400">N/A</span>
				) : (
					lightFormat(
						parseISO(advert.offer_valid_until),
						'dd/MM/yyyy'
					)
				)}
			</span>
			<span className={cellValueNarrow}>{advert.views}</span>
			<span className={cellValue}>
				{advert.offer_archived ? (
					<p className="flex items-center justify-center px-3 py-1 text-xs tracking-wider text-gray-600 bg-gray-700 bg-opacity-5 rounded-2xl">
						ARCHIVED
					</p>
				) : (
					<p className="flex items-center justify-center px-3 py-1 text-xs tracking-wider text-gray-600 bg-indigo-600 bg-opacity-10 rounded-2xl">
						EXPIRED
					</p>
				)}
			</span>

			<span className={cellValue}>
				<span className="w-44" />
			</span>
		</Link>

		{/* mobile zone */}
		{mobileViewOn && activeId === advert.id && (
			<div className="flex flex-col lg:hidden">
				<div className="flex justify-between p-2 bg-gray-100">
					<span className="text-gray-500">Submitted</span>
					<span className="">
						{lightFormat(parseISO(advert.createdAt), 'dd/MM/yyyy')}
					</span>
				</div>
				<div className="flex justify-between p-2">
					<span className="text-gray-500">Ends</span>
					<span className="">
						{advert.offer_valid_until === '9999-12-31' ? (
							<span className="text-gray-500">N/A</span>
						) : (
							lightFormat(
								parseISO(advert.offer_valid_until),
								'dd/MM/yyyy'
							)
						)}
					</span>
				</div>
				<div className="flex justify-between p-2">
					<span className="text-gray-500">Views</span>
					<span>{advert.views}</span>
				</div>
				<span className="flex py-4 pl-2">
					<Link
						to={{
							pathname: `/details/${advert.id}`,
						}}
						className="flex items-center justify-between font-bold text-blue-700"
					>
						<Icon
							iconName="visibility"
							size="sm"
							className="fill-primary-700"
						/>
						<span className="ml-4 mr-6">View advert</span>
					</Link>
				</span>
			</div>
		)}
	</>
);

export default PreviousAdvertRow;
