import http from 'utils/http';
import config from 'utils/config';

const getCompanyProfile = (locale) => {
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/profile`;

	return http.get(uri).then(({ data }) => {
		if (data) {
			return {
				id: data.id,
				aboutUs: data.aboutUs,
				companyLogoUrl: data.companyLogo.url,
				companyLogoId: data.companyLogo.id,
				companyName: data.companyName,
				brightCompanyGuid: data.brightCompanyGuid,
			};
		}
		return {
			id: '',
			aboutUs: '',
			companyLogoUrl: '../placeholder_profile.png',
			companyLogoId: '',
			companyName: '',
			brightCompanyGuid: '',
		};
	});
};
export default getCompanyProfile;
