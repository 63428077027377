import React from 'react';
import Uploader from 'pages/components/uploader';
import uploadImage from 'services/uploadImage';

const AdvertImage = ({
	setUploadError,
	heroImage,
	setHeroImage,
	setHasImage,
	uploadError,
	locale,
}) => (
	<>
		<div className="space-y-2">
			<p id="advert-image-heading">Advert Image</p>
			<Uploader
				uploadService={uploadImage}
				locale={locale}
				onError={setUploadError}
				onUploaded={(data) => {
					setHeroImage(data);
					setHasImage(true);
				}}
				initialImageUrl={heroImage?.url}
				existingFilename={heroImage?.filename}
			/>
			<div className="text-xs text-gray-400">
				Max 5mb, .jpg, .png 1600px x 900px (16:9)
			</div>
		</div>
		{uploadError && (
			<p className="font-normal text-error-500">
				There was a problem with this upload.
			</p>
		)}
	</>
);

export default AdvertImage;
