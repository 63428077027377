import React from 'react';
import { trackEvent } from 'utils/ga';
import ensureUrlBeginsWithHttp from '../../utils/urlCheck';

const PreviewButton = ({
	previewAllowed,
	errors,
	SUBMISSION_FORM,
	brightCompanyDetails,
	selectedCategory,
	selectedRegion,
	heroImage,
	advertiserImage,
	advertData,
	setPreviewData,
	setCurrentStep,
}) => (
	<button
		disabled={
			!previewAllowed ||
			errors.advertiser_about_us ||
			errors.advertiser_logo
		}
		type="button"
		data-testid="preview-button"
		className="px-4 py-2 m-2 font-bold text-white bg-blue-700 rounded hover:bg-blue-800 disabled:bg-gray-400 disabled:cursor-default"
		onClick={() => {
			if (
				previewAllowed &&
				!errors.advertiser_about_us &&
				!errors.advertiser_logo
			) {
				trackEvent('Submission', 'click preview');
				const ad = SUBMISSION_FORM.current.values;
				ad.advertiser_name = brightCompanyDetails.name;
				ad.offer_category =
					selectedCategory.text || advertData?.offer_category;
				ad.offer_region =
					selectedRegion.text || advertData?.offer_region;
				ad.hero = heroImage?.url ?? '../placeholder_hero.png';
				ad.advertiser_logo = advertiserImage.url;
				ad.offer_redemption_url = ensureUrlBeginsWithHttp(
					ad.offer_redemption_url
				);
				setPreviewData(ad);

				window.scrollTo(0, 0);

				setCurrentStep('preview');
			}
		}}
	>
		Preview Details
	</button>
);

export default PreviewButton;
