import http from 'utils/http';
import config from 'utils/config';

const createCompanyProfile = (payload, locale) => {
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/profile_create`;

	return http.post(uri, { data: payload }).then((data) => data);
};

export default createCompanyProfile;
