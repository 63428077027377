import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function convertUTCToLocalDate(date) {
	if (!date) {
		return date;
	}
	date = new Date(date);
	date = new Date(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate()
	);
	return date;
}

function convertLocalToUTCDate(date) {
	if (!date) {
		return date;
	}
	date = new Date(date);
	date = new Date(
		Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
	);
	return date;
}

export default function UTCDatePicker({ selected, onChange, ...props }) {
	return (
		<DatePicker
			className="p-2 border border-gray-300 rounded"
			dateFormat="dd/MM/yyyy"
			selected={convertUTCToLocalDate(selected)}
			onChange={(date) => onChange(convertLocalToUTCDate(date))}
			{...props}
		/>
	);
}
