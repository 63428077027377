const validate = (values) => {
	const errors = {};

	if (values.companyName === '') {
		errors.companyName = 'Company name is required';
	}
	if (values.aboutUs === '') {
		errors.aboutUs = 'About us is required';
	}
	if (values.companyLogoUrl === '') {
		errors.companyLogoUrl = 'Please upload a company logo';
	}

	return errors;
};

export default validate;
