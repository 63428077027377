import React from 'react';
import { trackEvent } from 'utils/ga';

const CategorySelector = ({
	id,
	name,
	onChange,
	defaultText,
	availableCategories,
	defaultSelection,
	setMarketplaceFilter,
	setIsLoading,
}) => (
	<>
		<select
			id={id}
			data-testid="offer-category"
			name={name}
			className="px-2 py-3 border rounded"
			onChange={
				onChange ||
				((e) => {
					if (setIsLoading) {
						setIsLoading(true);
					}
					if (setMarketplaceFilter) {
						setMarketplaceFilter(e.target.value);
					}
					trackEvent('Marketplace', 'click category filter');
				})
			}
		>
			<option key="default" value="-1">
				{defaultText}
			</option>

			{availableCategories.map((category) => (
				<option
					key={category.id}
					value={category.id}
					selected={defaultSelection === category.id || null}
				>
					{category.name}
				</option>
			))}
		</select>
	</>
);
export default CategorySelector;
