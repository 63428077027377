import http from 'utils/http';
import config from 'utils/config';

const updateAdvert = (payload, locale) => {
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/update`;
	return http.put(uri, { data: payload }).then((data) => data);
};

export default updateAdvert;
