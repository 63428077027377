import { useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import recordInteraction from 'services/telemetry';

export default function useTelemetry(locale) {
	const location = useLocation();
	const routeMatch = useMatch('/details/:id');

	useEffect(() => {
		recordInteraction(
			{
				action: location.pathname,
				advertId: routeMatch ? routeMatch.params.id : null,
			},
			locale
		);
	}, [locale, location, routeMatch]);
}
