const sliderSettings = (isLoading, totalSpotlightOffers) => ({
	lazyLoad: true,
	speed: 300,
	// eslint-disable-next-line no-nested-ternary
	slidesToShow: isLoading
		? 0
		: totalSpotlightOffers >= 1
		? 1
		: totalSpotlightOffers,
	// eslint-disable-next-line no-nested-ternary
	slidesToScroll: isLoading ? 0 : totalSpotlightOffers > 1 ? 1 : 0,
	initialSlide: 0,
	infinite: true,
	arrows: isLoading ? false : totalSpotlightOffers > 1,
	dots: false,
	autoplay: true,
	autoplaySpeed: 4000,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				// eslint-disable-next-line no-nested-ternary
				slidesToShow: isLoading
					? false
					: totalSpotlightOffers >= 1
					? 1
					: 1,
				// eslint-disable-next-line no-nested-ternary
				slidesToScroll: isLoading
					? false
					: totalSpotlightOffers >= 1
					? 1
					: 1,
				initialSlide: 1,
				infinite: isLoading ? false : totalSpotlightOffers > 1,
				arrows: isLoading ? false : totalSpotlightOffers > 1,
				dots: false,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				initialSlide: 1,
				infinite: isLoading ? false : totalSpotlightOffers > 1,
				dots: isLoading ? false : totalSpotlightOffers > 1,
				arrows: false,
			},
		},
	],
});

export default sliderSettings;
