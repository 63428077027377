import React from 'react';
import { trackEvent } from 'utils/ga';
import recordInteraction from 'services/telemetry';
import { useLocaleContext } from 'context/LocaleContext';
import DetailsComponent from '../../../components/details';

const PreviewPageView = ({
	advertId,
	setCurrentStep,
	previewData,
	SUBMISSION_FORM,
}) => {
	const { languageCode } = useLocaleContext();

	return (
		<div className="space-y-4 w-full">
			<div
				className={`${
					advertId ? 'top-20 ' : '-top-5 '
				}z-20 sticky md:px-12 max-w-screen-xl mx-auto mb-6 md:mb-4 pb-3`}
			>
				<div className="pl-4 md:pl-8 pr-1 bg-white flex items-center justify-between rounded-b-md">
					<div className="text-2xl font-bold">Advert preview</div>
					<div className="flex flex-col items-end px-3 py-1 rounded bg-gray-50">
						<div className="flex items-center space-x-4">
							<p className="font-bold">Happy with this advert?</p>
							<button
								type="submit"
								className="px-4 py-2 m-2 font-bold text-white bg-blue-700 rounded hover:bg-blue-800"
								onClick={async () => {
									trackEvent('Marketplace', 'click submit');
									setCurrentStep('submit');
									recordInteraction(
										{ action: 'try submit' },
										languageCode
									);
									await SUBMISSION_FORM.current.handleSubmit();
								}}
							>
								Submit
							</button>
						</div>
						<button
							type="button"
							className="text-blue-700 underline"
							onClick={() => {
								trackEvent('Marketplace', 'click back');
								setCurrentStep('create');
								recordInteraction(
									{ action: 'cancel submit' },
									languageCode
								);
							}}
						>
							or back to {advertId ? 'Edit' : 'Create'}
						</button>
					</div>
				</div>
			</div>
			{previewData && <DetailsComponent advert={previewData} isPreview />}
		</div>
	);
};

export default PreviewPageView;
