import React from 'react';
import Stars from './assets/stars.svg';
import Clouds from './assets/clouds.svg';

const NotFound = () => (
	<>
		<div className="absolute inset-0 flex flex-col items-center z-0">
			<img className="w-full" src={Stars} alt="alt" />
			<img className="w-full mt-24" src={Clouds} alt="alt" />
		</div>
		<div className="w-full">
			<div className="w-full">
				<div className="absolute inset-x-0 top-0 text-center text-error-sm lg:text-error-lg font-bold text-blue-700 opacity-10 z-0 leading-none">
					404
				</div>
				<div className="relative space-y-6 z-10 text-center pt-32 lg:pt-56">
					<h1 className="text-3xl lg:text-4xl font-bold text-blue-700">
						Page Not Found
					</h1>
					<p className="lg:text-xl">
						This page seemingly does not exist
					</p>
					<a
						href="/groups"
						className="inline-block bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded"
					>
						Back to marketplace
					</a>
				</div>
			</div>
		</div>
	</>
);

export default NotFound;
