import http from 'utils/http';
import config from 'utils/config';

const archiveOffer = (offerId, locale) => {
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/archive/${offerId}`;

	return http.put(uri, { data: offerId }).then((data) => data);
};

export default archiveOffer;
