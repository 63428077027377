import React, { useState, useEffect } from 'react';
import Searchbar from 'pages/components/searchbar';
import ClearButton from 'pages/components/clearbutton';
import { useLocaleContext } from 'context/LocaleContext';
import { useViewportContext } from 'context/ViewportContext';
import FilterButton from 'styles/images/settings-sliders.svg';
import FilterButtonWhite from 'styles/images/settings-sliders-white.svg';
import AudienceSelector from '../../selectors/audienceSelector';
import RegionSelector from '../../selectors/regionSelector';
import CategorySelector from '../../selectors/categorySelector';

const AdvertFilter = ({
	userInput,
	setUserInput,
	searchTerm,
	setSearchTerm,
	isLoading,
	setIsLoading,
	availableCategories,
	availableRegions,
	selectedCategoryId,
	selectedRegionId,
	selectedAudience,
	setMarketplaceFilter,
	setRegionFilter,
	setAudienceFilter,
	filteredTotal = 0,
	total = 0,
	navigateToAllAdvertsWithFilters,
}) => {
	const resultsText = () => {
		if (isLoading) {
			return 'Searching...';
		}
		if (total > 0) {
			return `Showing ${filteredTotal} of ${total} results`;
		}

		return '';
	};

	const [showFilters, setShowFilters] = useState(false);
	const displayClearFilters = !navigateToAllAdvertsWithFilters;

	const { currentLocale } = useLocaleContext();
	const { isLargeViewport } = useViewportContext();
	useEffect(() => {
		if (isLargeViewport) {
			return setShowFilters(true);
		}
	}, [isLargeViewport]);

	return (
		<>
			<div className="space-y-12">
				<div className="flex flex-col-reverse justify-between w-full p-4 bg-white bg-opacity-50 border-2 border-gray-200 shadow-xl lg:flex-col xl:flex-row rounded-xl">
					{showFilters && (
						<div className="flex flex-col w-full my-2 lg:flex-row lg:items-center">
							<h1 className="mt-3 mb-2 text-lg font-bold md:ml-2 md:mr-5 lg:mb-0 lg:mt-0">
								Filter:
							</h1>
							<div className="flex flex-col mb-2 lg:mr-4 lg:mb-0">
								<CategorySelector
									id="offer_category"
									name="offer_category"
									defaultText="All Categories"
									setMarketplaceFilter={setMarketplaceFilter}
									setIsLoading={setIsLoading}
									availableCategories={availableCategories}
									defaultSelection={selectedCategoryId}
								/>
							</div>
							<div className="flex flex-col mx-0">
								<RegionSelector
									id="offer_region"
									name="offer_region"
									defaultText={
										currentLocale?.countryCode === 'ca'
											? 'All Provinces'
											: 'All Regions'
									}
									setRegionFilter={setRegionFilter}
									setIsLoading={setIsLoading}
									availableRegions={availableRegions}
									defaultSelection={selectedRegionId}
								/>
							</div>
							<div className="flex flex-col mt-2 lg:ml-4 lg:mt-0">
								<AudienceSelector
									id="offer_audience"
									name="offer_audience"
									defaultText="All Offers"
									setAudienceFilter={setAudienceFilter}
									setIsLoading={setIsLoading}
									defaultSelection={selectedAudience}
								/>
							</div>
							{displayClearFilters && (
								<ClearButton
									setIsLoading={
										!selectedCategoryId &&
										!selectedRegionId &&
										!selectedAudience
											? null
											: setIsLoading
									}
									setMarketplaceFilter={setMarketplaceFilter}
									setRegionFilter={setRegionFilter}
									setAudienceFilter={setAudienceFilter}
								/>
							)}
						</div>
					)}
					<div
						className="flex flex-row items-center lg:w-693 xl:w-5/12"
						data-testid="filter-searchbar"
					>
						<Searchbar
							userInput={userInput}
							setUserInput={setUserInput}
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							setIsLoading={setIsLoading}
							isLargeViewport={isLargeViewport}
						/>
						<button
							type="button"
							className={`${
								showFilters
									? 'bg-blue-700 hover:bg-blue-800'
									: 'bg-white hover:bg-gray-100'
							} lg:hidden ml-1 border border-gray-200 rounded-md p-2.5`}
							onClick={() => setShowFilters(!showFilters)}
						>
							<img
								className="w-8"
								src={
									showFilters
										? FilterButtonWhite
										: FilterButton
								}
								alt="alt"
							/>
						</button>
					</div>
				</div>

				<p data-testid="results-text">{resultsText()}</p>
			</div>
		</>
	);
};

export default AdvertFilter;
