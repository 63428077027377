import React from 'react';

export type NZFlagProps = React.SVGAttributes<SVGElement> & {
	className?: string;
};

export const NZFlag = ({ className }: NZFlagProps): React.ReactElement => (
	<svg
		width="32"
		height="24"
		viewBox="0 0 64 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		aria-hidden="true"
	>
		<path d="M64 0H0V48H64V0Z" fill="#202979" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M52.9989 15.9347L51.0438 14.4874L49.0177 15.8275L49.782 13.5136L47.8802 11.9859L50.3062 11.9948L51.1682 9.71659L51.9058 12.0395L54.3318 12.1556L52.3591 13.5761L52.9989 15.9347Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M52.2168 14.7644L51.0615 13.9246L49.8708 14.7287L50.3062 13.3618L49.1865 12.4773L50.6083 12.4684L51.106 11.1193L51.5503 12.4862L52.9722 12.5309L51.8258 13.3796L52.2168 14.7644Z"
			fill="#E52421"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M46.4899 28.4064L44.526 26.959L42.5 28.2992L43.2641 25.9852L41.3713 24.4575L43.7973 24.4665L44.6504 22.1882L45.388 24.5111L47.814 24.6273L45.8413 26.0478L46.4899 28.4064Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M45.6991 27.2362L44.5438 26.3965L43.3619 27.2005L43.7973 25.8336L42.6688 24.9491L44.0995 24.9401L44.5883 23.5911L45.0326 24.958L46.4633 25.0027L45.3081 25.8514L45.6991 27.2362Z"
			fill="#E52421"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M60.8614 27.1772L58.9063 25.7298L56.8802 27.07L57.6445 24.7561L55.7427 23.2284L58.1688 23.2374L59.0307 20.9592L59.7683 23.282L62.1943 23.3981L60.2216 24.8187L60.8614 27.1772Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M60.0793 26.007L58.924 25.1673L57.7333 25.9713L58.1688 24.6044L57.049 23.7199L58.4708 23.711L58.9685 22.3619L59.4128 23.7288L60.8347 23.7735L59.6883 24.6222L60.0793 26.007Z"
			fill="#E52421"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M52.8241 44.919L50.8691 43.4717L48.843 44.8118L49.6072 42.4979L47.7055 40.9702L50.1315 40.9791L50.9935 38.7009L51.7311 41.0238L54.1571 41.1399L52.1844 42.5604L52.8241 44.919Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M52.0421 43.7486L50.8868 42.9088L49.696 43.7129L50.1315 42.346L49.0118 41.4615L50.4336 41.4525L50.9313 40.1035L51.3756 41.4704L52.7974 41.5151L51.6511 42.3638L52.0421 43.7486Z"
			fill="#E52421"
		/>
		<path
			d="M4.5 0L0 0.0001V0V3.375L35.5 30H40V26.625L4.5 0Z"
			fill="white"
		/>
		<path d="M35.5 0L0 26.625V30H4.5L40 3.375V0H35.5Z" fill="white" />
		<path d="M23.3125 0H16.6875V30H23.3125V0Z" fill="white" />
		<path d="M40 10H0V20H40V10Z" fill="white" />
		<path d="M40 12H0V18H40V12Z" fill="#CC1517" />
		<path d="M22 0H18V30H22V0Z" fill="#CC1517" />
		<path
			d="M13.3334 20L0.00012207 30L2.98142 29.9999L16.3147 20H13.3334Z"
			fill="#CC1517"
		/>
		<path d="M0 0V2.2361L10.352 9.9999L13.3333 10L0 0Z" fill="#CC1517" />
		<path
			d="M37.0187 0L23.6854 10L26.6668 9.9999L40 0H37.0187Z"
			fill="#CC1517"
		/>
		<path d="M40 30L26.6667 20H29.648L40 27.764V30Z" fill="#CC1517" />
	</svg>
);

export default NZFlag;
