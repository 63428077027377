import http from 'utils/http';
import config from 'utils/config';

export interface Tag {
	id: string;
	tag: string;
	public: boolean;
}

const getTags = (locale: string): Promise<Tag[]> => {
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/tags`;

	return http.get(uri).then(({ data }) => data);
};

export default getTags;
