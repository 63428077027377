import React from 'react';
import { useLocaleContext } from 'context/LocaleContext';

const LocaleSelector = ({ isLanding }) => {
	const { setNewLocale, preferredLocale } = useLocaleContext();

	return (
		<select
			className="p-2 mr-2 bg-transparent border border-gray-200 rounded-md cursor-pointer"
			onChange={(e) => setNewLocale(e.target.value, true, isLanding)}
			value={preferredLocale}
		>
			<option value="en">UK</option>
			<option value="ie">Ireland</option>
			<option value="au">Australia</option>
			<option value="nz">New Zealand</option>
			<option value="ca">Canada</option>
		</select>
	);
};
export default LocaleSelector;
