import React from 'react';

const Skeleton = () => (
	<div className="flex flex-col flex-auto truncate duration-100 transform bg-white border rounded-lg hover:shadow-lg transition-transform-shadow hover:-translate-y-1 animate-pulse ">
		<div className="flex items-start p-4 space-x-4">
			<div className="bg-white border border-gray-300 rounded shadow-lg h-14 w-14">
				<svg
					width="60"
					height="60"
					viewBox="0 0 177 100"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				/>
			</div>
			<div className="flex-grow overflow-hidden">
				<h3 className="text-xl font-bold text-gray-200 bg-gray-200 rounded-lg">
					advert.advertiser_name
				</h3>
				<p className="inline-block px-3 py-1 text-xs text-gray-100 bg-gray-100 rounded-full">
					advert.offer_category
				</p>
			</div>
		</div>
		<div className="h-56">
			<div className="w-full h-full bg-gray-200" src="" alt="" />
		</div>
		<div className="flex flex-col flex-grow p-4">
			<p className="flex-auto pb-6 text-gray-100 bg-gray-100 rounded-lg">
				advert.offer_promotional_text
			</p>
			<div className="flex items-end justify-between space-x-8 overflow-hidden">
				<div className="flex-shrink">
					<div className="flex items-center px-3 space-x-2 text-gray-100 bg-gray-100 rounded-full">
						<svg
							xmlns="http://www.w3.org/1000/svg"
							width="24"
							height="24"
							stroke="#2c3e50"
							fill="none"
						>
							<path d="M0 0h24v24H0z" stroke="none" />
							<path d="M12 6l4 6 5-4-2 10H5L3 8l5 4z" />
						</svg>
						<span className="hidden text-xs leading-none tracking-widest uppercase xl:block">
							Spotlight
						</span>
					</div>
				</div>

				<div className="flex flex-col justify-end flex-auto text-right">
					<p className="leading-none text-gray-100 bg-gray-100 rounded-lg">
						Save up to
					</p>
					<p className="flex-auto text-xl font-bold text-gray-100 truncate bg-gray-100 rounded-lg xl:text-2xl">
						100
						<span>%</span>
					</p>
				</div>
			</div>
		</div>
	</div>
);
export default Skeleton;
