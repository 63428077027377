import http from 'utils/http';
import config from 'utils/config';
import mapAdvert from 'utils/mappers/advertMapper';
import { GroupSummary } from '../../domain/Groups';

const getGroups = async (locale, pageSize): Promise<GroupSummary[]> => {
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/groups/${pageSize}`;

	const { data } = await http.get(uri);
	const { groups } = data;

	return groups.map((group) => {
		const mappedGroup = {
			...group,
			data: {
				adverts: group.data.adverts.map((advert) => mapAdvert(advert)),
			},
		};
		return mappedGroup;
	});
};

export default getGroups;
