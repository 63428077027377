import React from 'react';
import { trackEvent } from 'utils/ga';
import recordInteraction from 'services/telemetry';
import { useLocaleContext } from 'context/LocaleContext';

const selectAdGuide = (currentLocale) => {
	if (currentLocale.countryCode === 'ie')
		return '/Bright_Exchange_Ad_Guide_ROI.pdf';
	if (currentLocale.countryCode === 'au')
		return '/Bright_Exchange_Ad_Guide_AUS.pdf';
	if (currentLocale.countryCode === 'nz')
		return '/Bright_Exchange_Ad_Guide_NZ.pdf';
	if (currentLocale.countryCode === 'ca')
		return '/Bright_Exchange_Ad_Guide_CA.pdf';
	return '/Bright Exchange Ad guide.pdf';
};

const IntroductionBanner = () => {
	const { currentLocale } = useLocaleContext();

	return (
		<div className="items-center max-w-screen-xl px-6 py-4 mx-auto space-y-3 border-2 border-blue-200 rounded lg:px-12 lg:space-y-0 lg:mt-0 lg:flex bg-blue-50">
			<p className="flex-auto">Need help creating an advert?</p>
			<div>
				<a
					className="px-4 py-2.5 mr-2 font-bold text-white bg-blue-700 rounded my hover:bg-blue-800"
					href={selectAdGuide(currentLocale)}
					target="_blank"
					onClick={() => {
						trackEvent('Submission', 'view guide');
						recordInteraction(
							{
								action: 'create - view guide',
							},
							currentLocale.languageCode
						);
					}}
					rel="noreferrer"
				>
					3 simple steps to get you started
				</a>
			</div>
		</div>
	);
};

export default IntroductionBanner;
