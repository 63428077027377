import React from 'react';
import Icon from '@brighthr/component-icon';
import { trackEvent } from 'utils/ga';

const Searchbar = ({
	userInput,
	setUserInput,
	searchTerm,
	setSearchTerm,
	setIsLoading,
	isLargeViewport,
}) => (
	<form
		className="w-full"
		onSubmit={(e) => {
			e.preventDefault();
			if (userInput !== searchTerm) {
				setIsLoading(true);
			}
			setSearchTerm(userInput);
			trackEvent('Marketplace', 'enter search term');
		}}
	>
		<div className="flex items-center w-full p-1 bg-white border-t border-b border-l border-r border-gray-200 rounded-md">
			<div className="flex-1">
				<Icon iconName="search" className="fill-gray-400" size="lg" />
			</div>
			<input
				data-testid="searchbar"
				className="w-full p-2 outline-none "
				maxLength={32}
				value={userInput}
				placeholder={
					isLargeViewport ? 'Search marketplace' : 'Search...'
				}
				onChange={(e) => {
					setUserInput(e.target.value);
				}}
			/>
			{searchTerm.length > 0 && (
				<div className="flex-1">
					<button
						id="cross-button"
						type="button"
						className="flex items-center justify-center mr-2"
						onClick={() => {
							setIsLoading(true);
							setUserInput('');
							setSearchTerm('');
						}}
						aria-label="Clear search term"
					>
						<Icon
							iconName="cross-thin"
							className="fill-gray-600"
							size="sm"
						/>
					</button>
				</div>
			)}
			<div className="flex items-center justify-center bg-white border-gray-200 rounded-r-md">
				<button
					data-testid="submit-search"
					className="px-3 py-2 text-sm font-bold text-white bg-blue-700 rounded hover:bg-blue-800 lg:px-4"
					type="submit"
				>
					Search
				</button>
			</div>
		</div>
	</form>
);
export default Searchbar;
