import React from 'react';
import { Advert } from '../../../domain/Advert';

interface Props {
	advert: Advert;
}

const OfferCard = ({ advert }: Props) => (
	<div className="flex flex-col flex-auto h-full duration-100 transform bg-white border rounded-lg transition-transform-shadow">
		<div className="flex items-start p-4 space-x-4">
			<div className="flex-none bg-white border border-gray-300 rounded shadow-lg h-14 w-14">
				<img
					className="object-cover w-full h-full"
					src={`${advert.advertiserLogo}?fit=crop&h=60&w=60`}
					alt={advert.advertiserName}
				/>
			</div>
			<div className="flex-grow overflow-hidden">
				<h3 className="text-xl font-bold truncate">
					{advert.advertiserName}
				</h3>
				<p className="inline-block px-3 py-1 text-xs text-gray-500 bg-gray-100 rounded-full">
					{advert.offerCategory}
				</p>
			</div>
		</div>
		<img
			className="object-cover w-full aspect-[16/9]"
			src={`${advert.offerHeroImage}?h=224`}
			alt={advert.advertiserName}
		/>
		<div className="flex flex-col flex-grow p-4">
			<p className="flex-auto pb-6 text-gray-500">
				{advert.offerPromotionalText}
			</p>
			<div className="flex items-end justify-between space-x-8 overflow-hidden">
				{advert.isPercentageDiscount &&
					advert.discountText.length !== 0 && (
						<div className="flex flex-col justify-end flex-auto text-right">
							<p className="leading-none text-gray-500">
								Save up to
							</p>
							<p className="flex-auto text-xl font-bold truncate xl:text-2xl">
								{advert.discountText}
								<span>%</span>
							</p>
						</div>
					)}

				{!advert.isPercentageDiscount && (
					<p className="flex-auto text-xl font-bold text-right truncate xl:text-2xl">
						{advert.discountText}
					</p>
				)}
			</div>
		</div>
	</div>
);

export default OfferCard;
