// import { get } from 'bright-utils/brightCache';
import getToken from 'utils/getToken';

const buildUserData = () => {
	const { email, givenName, role, locale, companyId } = getToken();
	return {
		email,
		full_name: givenName,
		language: locale,
		// country_code: get('countryCode'),
		// registered_date: get('dateRegisteredUtc'),
		role_poc: role,
		// role_admin: user.allRoles?.includes('HrAdministrator') ?? false,
		// role_manager: user.allRoles?.includes('DepartmentManager') ?? false,
		// status: user.packageType === 'free' ? 'free' : 'paid',

		company: {
			id: companyId,
			// name: get('companyName'),
			// tags: [get('countryCode')],
		},
	};
};

export default buildUserData;
