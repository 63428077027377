const addLocaleToLink = (currentLocale) => {
	if (
		currentLocale.countryCode === 'ie' ||
		currentLocale.countryCode === 'au' ||
		currentLocale.countryCode === 'nz' ||
		currentLocale.countryCode === 'ca'
	)
		return `${currentLocale.countryCode}/`;
	return '';
};

export default addLocaleToLink;
