import React from 'react';
import Standard from 'pages/components/standard';

const PreviewCard = ({
	values,
	advertiserImage,
	brightCompanyDetails,
	heroImage,
	selectedCategory,
	isPercentageDiscount,
	discountText,
}) => (
	<>
		<h1 className="font-bold" id="advert-previewcard-heading">
			Marketplace Preview
		</h1>

		<div className="border border-gray-400 rounded-lg shadow-xl">
			<Standard
				advert={{
					advertiser_logo: advertiserImage?.url,
					advertiser_name: `${brightCompanyDetails.name}`,
					hero: heroImage?.url ?? '../placeholder_hero.png',
					offer_category: selectedCategory.text,
					offer_promotional_text: values.offer_promotional_text,
					offer_is_percentage_discount: isPercentageDiscount,
					discount_text: discountText,
				}}
			/>
		</div>
	</>
);

export default PreviewCard;
