import React from 'react';

const ClearButton = ({
	setIsLoading,
	setMarketplaceFilter,
	setRegionFilter,
	setAudienceFilter,
}) => (
	<button
		id="clear-button"
		type="button"
		className="whitespace-nowrap p-1 mt-2 ml-0 text-left text-gray-400 hover:text-gray-600 lg:mt-0 lg:ml-2 2xl:ml-3"
		onClick={() => {
			if (setIsLoading) {
				setIsLoading(true);
			}
			setMarketplaceFilter('-1');
			setRegionFilter('-1');
			setAudienceFilter('-1');
		}}
	>
		Clear all
	</button>
);
export default ClearButton;
