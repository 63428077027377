import React from 'react';
import cn from 'classnames';

import { useLocaleContext } from 'context/LocaleContext';
import { AUFlag, CAFlag, IEFlag, NZFlag, UKFlag } from './flags';

export type CompactLocaleSelectorProps =
	React.ButtonHTMLAttributes<HTMLDivElement> & {
		isLanding?: boolean;
		className?: string;
	};

const FlagIcon = ({
	locale,
	className,
}: {
	locale: string;
	className: string;
}) => {
	switch (locale) {
		case 'en-gb':
			return <UKFlag className={className} />;
		case 'au':
			return <AUFlag className={className} />;
		case 'ca':
			return <CAFlag className={className} />;
		case 'ie':
			return <IEFlag className={className} />;
		case 'nz':
			return <NZFlag className={className} />;
		default:
			return <UKFlag className={className} />;
	}
};

export const CompactLocaleSelector = ({
	isLanding = false,
	className,
}: CompactLocaleSelectorProps) => {
	const { setNewLocale, preferredLocale } = useLocaleContext();

	return (
		<div
			className={cn(
				className,
				'flex border-2 border-gray-300 rounded-md bg-white py-1 px-1 sm:px-3 items-center',
				'focus-within:border-solid focus-within:border-2 focus-within:border-blue-700'
			)}
		>
			<React.Fragment>
				<FlagIcon
					locale={preferredLocale}
					className={cn('rounded-md')}
				/>
				<select
					name="Locale"
					className={cn(
						'py-2 px-0 mr-0 sm:px-2 sm:mr-2 bg-transparent rounded-md cursor-pointer outline-none'
					)}
					onChange={(e) => {
						setNewLocale(e.target.value, true, isLanding);
					}}
					value={preferredLocale}
				>
					<option value="en">UK</option>
					<option value="ie">Ireland</option>
					<option value="au">Australia</option>
					<option value="nz">New Zealand</option>
					<option value="ca">Canada</option>
				</select>
			</React.Fragment>
		</div>
	);
};
export default CompactLocaleSelector;
