import sessionId from '../brightSession';

export const initTrackJS = () => {
	if (window.TrackJS) {
		window.TrackJS.configure({
			sessionId,
		});
	}
};

export default initTrackJS;
