import Config from '../../utils/config/index';

/**
 * Determines whether Userpilot is enabled for the current environment
 *
 * You can force enable Userpilot on non-production environments by setting
 * the local storage key `userpilotEnabled`
 * to `true`
 * @return {boolean} - boolean specifying whether the form is valid
 */
const useUserpilotEnabled = (): boolean => {
	const BRIGHT = Config();
	return (
		BRIGHT.USERPILOT_ENABLED ||
		localStorage.getItem('userpilotEnabled') === 'true'
	);
};

export default useUserpilotEnabled;
