import React from 'react';
import dateFormat from 'dateformat';
import recordInteraction from 'services/telemetry';
import { Link } from 'react-router-dom';
import { trackEvent } from 'utils/ga';
import { useLocaleContext } from 'context/LocaleContext';

const DetailsComponent = ({ advert, isPreview }) => {
	const { currentLocale } = useLocaleContext();

	return (
		<>
			<div className="pb-24 mt-32">
				{!isPreview && (
					<div className="relative z-10 max-w-screen-xl mx-auto mb-8 -mt-24 sm:mb-8 md:-mt-26 md:mb-0 md:px-12">
						<div className="relative z-40 flex items-center mb-8 space-x-2 md:mb-8 lg:mb-6">
							<Link to="/groups">
								<div className="flex">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="stroke-current"
										width="24"
										height="24"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path d="M5 12h14M5 12l4 4M5 12l4-4" />
									</svg>
									Back to marketplace
								</div>
							</Link>
						</div>
					</div>
				)}

				<div className="relative z-10 max-w-screen-xl mx-auto space-y-12 lg:flex lg:flex-wrap lg:items-start md:px-12 lg:space-y-0">
					<div className="flex flex-col space-y-10 lg:space-y-6 lg:w-2/3 lg:pr-12 ">
						<img
							className="object-cover aspect-[16/9] w-full rounded-md bg-gradient-to-b from-transparent to-white"
							src={advert ? advert.hero : ''}
							alt="companyLogo"
						/>
						<div className="p-4 space-y-6 bg-white border border-gray-300 rounded shadow-lg md:p-8">
							<div className="flex flex-col items-start -mt-10 space-y-8 md:flex-row md:space-x-8 md:space-y-0 md:-mt-0">
								<div className="flex items-center justify-center flex-none w-20 h-20 bg-white border border-gray-300 rounded-md shadow-lg md:w-32 md:h-32">
									<img
										className="object-cover w-full h-full rounded-md"
										src={`${advert.advertiser_logo}?fit=crop&h=90&w=90`}
										alt={advert.advertiser_name}
									/>
								</div>
								<div className="flex-grow overflow-hidden">
									<h3 className="text-2xl font-bold xl:text-3xl">
										{advert.offer_promotional_text}
									</h3>
								</div>
							</div>
							<div className="block p-4 border-b border-gray-300 md:hidden">
								{advert.offer_is_percentage_discount &&
									advert.discount_text.length !== 0 && (
										<div className="flex flex-col justify-end flex-auto text-right">
											<p className="leading-none text-gray-500">
												Save up to
											</p>
											<p className="flex-auto text-2xl font-bold xl:text-3xl">
												{advert.discount_text}
												<span>%</span>
											</p>
										</div>
									)}
								{!advert.offer_is_percentage_discount && (
									<p className="flex-auto text-2xl font-bold text-right xl:text-3xl">
										{advert.discount_text}
									</p>
								)}
							</div>
							<div className="space-y-6">
								<div className="p-4 bg-gray-100 border-b border-gray-300 rounded">
									<h1 className="text-lg font-bold">
										About &apos;{advert.advertiser_name}
										&apos;
									</h1>
									<p className="whitespace-pre-wrap">
										{advert.advertiser_about_us}
									</p>
								</div>
								<div className="p-4 bg-gray-100 border-b border-gray-300 rounded">
									<h1 className="text-lg font-bold">
										Category
									</h1>
									<p>{advert.offer_category}</p>
								</div>
								<div className="p-4 bg-gray-100 border-b border-gray-300 rounded">
									<h1 className="text-lg font-bold">
										{currentLocale.countryCode === 'ca'
											? 'Province'
											: 'Region'}
									</h1>
									<p>{advert.offer_region}</p>
								</div>
								{advert.offer_redemption_instructions && (
									<div className="p-4 bg-gray-100 border-b border-gray-300 rounded">
										<h1 className="text-lg font-bold">
											How to Redeem
										</h1>
										<p className="whitespace-pre-wrap">
											{
												advert.offer_redemption_instructions
											}
										</p>
									</div>
								)}
								{advert.offer_promo_code && (
									<div>
										<h1 className="text-lg font-bold">
											Promo Code
										</h1>
										<p className="inline-block p-4 px-6 py-3 bg-gray-100 border-b border-gray-300 rounded ">
											{advert.offer_promo_code}
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="lg:w-1/3">
						<div className="overflow-hidden bg-white border border-gray-300 rounded shadow-lg">
							<div className="hidden p-4 border-b border-gray-300 md:block">
								{advert.offer_is_percentage_discount &&
									advert.discount_text.length !== 0 && (
										<div className="flex flex-col justify-end flex-auto text-right">
											<p className="leading-none text-gray-500">
												Save up to
											</p>
											<p className="flex-auto text-2xl font-bold xl:text-3xl">
												{advert.discount_text}
												<span>%</span>
											</p>
										</div>
									)}
								{!advert.offer_is_percentage_discount && (
									<p className="flex-auto text-2xl font-bold text-right xl:text-3xl">
										{advert.discount_text}
									</p>
								)}
							</div>
							<div>
								<div className="p-4 md:bg-gray-100">
									<h1 className="text-xl font-bold">
										{advert.offer_valid_until !== null &&
										advert.offer_valid_until !==
											'9999-12-31'
											? 'Offer Valid Until'
											: 'Unlimited Time Offer'}
									</h1>
									<p>
										{advert.offer_valid_until !== null &&
										advert.offer_valid_until !==
											'9999-12-31'
											? dateFormat(
													advert.offer_valid_until,
													'dd/mm/yyyy'
											  )
											: null}
									</p>
								</div>
							</div>
						</div>
						{advert.offer_redemption_url && (
							<a
								href={`${advert.offer_redemption_url}`}
								target="_blank"
								onClick={() => {
									trackEvent('Advert', 'click website');
									recordInteraction(
										{
											advertId: advert.id,
											action: `view website`,
										},
										currentLocale.languageCode
									);
								}}
								className="flex flex-wrap justify-center w-full py-4 my-2 font-bold text-white bg-blue-700 rounded hover:bg-blue-800"
							>
								<svg
									width="24"
									height="24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM2 12h20"
										stroke="#111"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
										className="stroke-current"
									/>
									<path
										d="M12 2a15.3 15.3 0 014 10 15.3 15.3 0 01-4 10 15.3 15.3 0 01-4-10 15.3 15.3 0 014-10v0z"
										stroke="#111"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
										className="stroke-current"
									/>
								</svg>
								<span className="px-2">Visit Website</span>
							</a>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default DetailsComponent;
