import http from 'utils/http';
import config from 'utils/config';
import mapAdvert from 'utils/mappers/advertMapper';
import { Group } from '../../domain/Groups';

interface Params {
	groupId: string | undefined;
	categoryId: string;
	regionId: string;
	audience: string;
	pageNumber: number;
	searchTerm: string;
	locale: string;
	pageSize?: number;
}

const getGroup = async (params: Params): Promise<Group> => {
	const { EXCHANGE_API } = config();
	const {
		groupId,
		categoryId,
		regionId,
		audience,
		pageNumber,
		searchTerm,
		locale,
		pageSize,
	} = params;
	const uri = `${EXCHANGE_API}/${locale}/group/${groupId}/${categoryId}/${regionId}/${audience}/${pageNumber}/${pageSize}/${searchTerm}`;

	const { data } = await http.get(uri);
	const { data: groupData } = data;
	const { adverts, filterCount } = groupData;

	const mappedAdverts = adverts.map((advert) => mapAdvert(advert));

	return {
		...data,
		adverts: mappedAdverts,
		filterCount,
	};
};

export default getGroup;
