import React, { useState, useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import getCategories from 'services/getCategories';
import getRegions from 'services/getRegions';
import getGroup from 'services/getGroup';

import ScrollToTopButton from 'pages/components/scrollToTop';
import { trackEvent } from 'utils/ga';
import { useLocaleContext } from 'context/LocaleContext';
import { useViewportContext } from 'context/ViewportContext';
import { Advert } from 'domain/Advert';
import { Group as DomainGroup } from 'domain/Groups';

import NoResults from 'assets/noresults.svg';
import NoMoreResults from 'assets/nomoreresults.svg';
import SkeletonAdvert from '../skeletonAdvert';
import AdvertFilter from './filter';
import Layout from '../../../layouts';
import OfferCard from '../offerCard';
import Spotlight from '../spotlight';

const Group = () => {
	const { id } = useParams();

	const [searchParams] = useSearchParams();
	const categoryId = searchParams.get('categoryId');
	const regionId = searchParams.get('regionId');
	const audience = searchParams.get('audience');
	const searchTermFromQuery = searchParams.get('searchTerm');

	const [group, setGroup] = useState<DomainGroup>({
		name: '',
		filterCount: { count: 0 },
		adverts: [],
	});
	const [adverts, setAdverts] = useState<Advert[]>([]);

	const [isLoading, setIsLoading] = useState(true);
	const [marketplaceFilter, setMarketplaceFilter] = useState(
		categoryId || '0'
	);
	const [regionFilter, setRegionFilter] = useState(regionId || '0');
	const [audienceFilter, setAudienceFilter] = useState(audience || '0');
	const [searchTerm, setSearchTerm] = useState(searchTermFromQuery || '');
	const [userInput, setUserInput] = useState(searchTermFromQuery || '');

	const [pageNumber, setPageNumber] = useState(1);

	const [availableCategories, setAvailableCategories] = useState([]);
	const [availableRegions, setAvailableRegions] = useState([]);

	const { languageCode } = useLocaleContext();
	const { isLargeViewport } = useViewportContext();

	useEffect(() => {
		Promise.all([getCategories(languageCode), getRegions(languageCode)])
			.then(([categories, regions]) => {
				setAvailableCategories(categories);
				setAvailableRegions(regions);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log('err', err);
			});
	}, [languageCode]);

	const getGroupAdverts = (isUpdate = false) =>
		getGroup({
			groupId: id,
			categoryId: marketplaceFilter,
			regionId: regionFilter,
			audience: audienceFilter,
			pageNumber: isUpdate ? pageNumber + 1 : 1,
			pageSize: isLargeViewport ? 100 : 20,
			searchTerm,
			locale: languageCode,
		});

	useEffect(() => {
		getGroupAdverts()
			.then((groupResponse) => {
				const { adverts: groupResponseAdverts } = groupResponse;
				setGroup(groupResponse);
				setAdverts(groupResponseAdverts);
				setIsLoading(false);
				setPageNumber(1);
				window.scrollTo(0, 0);
			})
			.catch((err) => {
				console.log('err', err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		marketplaceFilter,
		regionFilter,
		audienceFilter,
		searchTerm,
		languageCode,
		isLargeViewport,
	]);

	const handleNextPage = () => {
		getGroupAdverts(true).then((groupResponse) => {
			setAdverts(adverts.concat(groupResponse.adverts));
			setPageNumber(pageNumber + 1);
		});
	};

	const filterCount = group?.filterCount?.count || 0;
	const showLoadMoreOffers = adverts.length < filterCount;
	const totalAdverts = adverts.length;
	const advertsExist = totalAdverts >= 1;

	return (
		<>
			<Layout>
				<div className="space-y-4" data-testid="marketplace-view">
					<AdvertFilter
						isLoading={isLoading}
						userInput={userInput}
						setUserInput={setUserInput}
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
						setIsLoading={setIsLoading}
						availableCategories={availableCategories}
						availableRegions={availableRegions}
						filteredTotal={isLoading ? 0 : adverts.length}
						total={filterCount}
						selectedCategoryId={
							marketplaceFilter === '-1'
								? null
								: marketplaceFilter
						}
						selectedRegionId={
							regionFilter === '-1' ? null : regionFilter
						}
						selectedAudience={
							audienceFilter === '-1' ? null : audienceFilter
						}
						setMarketplaceFilter={setMarketplaceFilter}
						setRegionFilter={setRegionFilter}
						setAudienceFilter={setAudienceFilter}
						navigateToAllAdvertsWithFilters={null}
					/>

					<div className="space-y-4 md:space-y-0">
						{isLoading && (
							<>
								<div className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-4">
									<SkeletonAdvert />
									<SkeletonAdvert />
									<SkeletonAdvert />
								</div>
							</>
						)}
						{!isLoading && !advertsExist && (
							<React.Fragment>
								<div className="flex flex-col items-center justify-center">
									<img
										className="w-60"
										src={NoResults}
										alt="alt"
									/>
									<p className="text-2xl">
										No results found
										{searchTerm.length > 0
											? ` for '${searchTerm}'`
											: '!'}
									</p>
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
				{!isLoading && advertsExist && (
					<React.Fragment>
						<div className="relative z-40 flex items-center mb-6 space-x-2 text-blue">
							<Link to="/groups">
								<div className="flex">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="stroke-current"
										width="24"
										height="24"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path d="M5 12h14M5 12l4 4M5 12l4-4" />
									</svg>
									Back to marketplace
								</div>
							</Link>
						</div>
						<Spotlight
							audience={audienceFilter}
							categoryId={marketplaceFilter}
							locale={languageCode}
							regionId={regionFilter}
							searchTerm={searchTerm}
						/>
						<div className="space-y-4">
							<div className="flex justify-between">
								{advertsExist && (
									<h2 className="mt-8 mb-4 text-3xl font-bold">
										{group.name}
									</h2>
								)}
							</div>

							<div className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 lg:grid-cols-3 gap-7">
								{adverts.map((standardAdvert) => (
									<Link
										key={standardAdvert.id}
										className="flex flex-col"
										to={{
											pathname: `/details/${standardAdvert.id}`,
										}}
										onClick={() => {
											trackEvent(
												'marketplace',
												'click standard card'
											);
										}}
									>
										<div className="h-full duration-100 transform hover:shadow-lg transition-transform-shadow hover:-translate-y-1">
											<OfferCard
												key={standardAdvert.id}
												advert={standardAdvert}
											/>
										</div>
									</Link>
								))}
							</div>

							{!showLoadMoreOffers && advertsExist && (
								<div className="flex flex-col items-center justify-center">
									<img
										className="w-60"
										src={NoMoreResults}
										alt="alt"
									/>
									<p className="text-2xl">No more results!</p>
								</div>
							)}
							{totalAdverts >= 5 && <ScrollToTopButton />}
						</div>
						{showLoadMoreOffers && (
							<div className="flex justify-center">
								<button
									type="button"
									onClick={() => {
										handleNextPage();
										trackEvent(
											'marketplace',
											'click load more'
										);
									}}
									className="px-4 py-2 mt-2 font-bold text-white bg-blue-700 rounded-md hover:bg-blue-800"
								>
									Load more offers
								</button>
							</div>
						)}
					</React.Fragment>
				)}
			</Layout>
		</>
	);
};

export default Group;
