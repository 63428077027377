import http from 'utils/http';
import config from 'utils/config';

const getMyAdverts = (locale) => {
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/my_adverts`;

	return http.get(uri).then(({ data }) => {
		const myCurrentAdverts = data.current.map(
			({
				id,
				advertiserName,
				advertiserLogo,
				advertiserAboutUs,
				offerHeroImage,
				offerCategory,
				offerRegion,
				offerPromoCode,
				offerPromotionalText,
				offerRedemptionInstructions,
				offerSpotlight,
				offerValidFrom,
				offerValidUntil,
				offerRedemptionUrl,
				isPercentageDiscount,
				discountText,
				approvalStatus,
				offerArchived,
				createdAt,
				views,
			}) => ({
				id,
				advertiser_name: advertiserName,
				hero:
					offerHeroImage !== null
						? offerHeroImage.url
						: '../placeholder_hero.png',
				advertiser_about_us: advertiserAboutUs,
				advertiser_logo:
					advertiserLogo != null
						? `${advertiserLogo.url}`
						: '../placeholder_profile.png',
				offer_category: offerCategory.name,
				offer_region: offerRegion?.name,
				offer_promo_code: offerPromoCode,
				offer_promotional_text: offerPromotionalText,
				offer_redemption_instructions: offerRedemptionInstructions,
				offer_spotlight: offerSpotlight,
				offer_valid_from: offerValidFrom,
				offer_valid_until: offerValidUntil,
				offer_is_percentage_discount: isPercentageDiscount,
				discount_text: discountText,
				offer_redemption_url:
					offerRedemptionUrl === '' ? null : offerRedemptionUrl,
				approval_status: approvalStatus,
				offer_archived: offerArchived,
				createdAt,
				views,
			})
		);
		const myPreviousAdverts = data.previous.map(
			({
				id,
				advertiserName,
				advertiserAboutUs,
				offerCategory,
				offerRegion,
				offerPromoCode,
				offerPromotionalText,
				offerRedemptionInstructions,
				offerSpotlight,
				offerValidFrom,
				offerValidUntil,
				offerRedemptionUrl,
				isPercentageDiscount,
				discountText,
				approvalStatus,
				offerArchived,
				createdAt,
				views,
			}) => ({
				id,
				advertiser_name: advertiserName,
				advertiser_about_us: advertiserAboutUs,
				offer_category: offerCategory.name,
				offer_region: offerRegion?.name,
				offer_promo_code: offerPromoCode,
				offer_promotional_text: offerPromotionalText,
				offer_redemption_instructions: offerRedemptionInstructions,
				offer_spotlight: offerSpotlight,
				offer_valid_from: offerValidFrom,
				offer_valid_until: offerValidUntil,
				offer_is_percentage_discount: isPercentageDiscount,
				discount_text: discountText,
				offer_redemption_url:
					offerRedemptionUrl === '' ? null : offerRedemptionUrl,
				approval_status: approvalStatus,
				offer_archived: offerArchived,
				createdAt,
				views,
			})
		);

		const mappedData = {
			current: myCurrentAdverts,
			previous: myPreviousAdverts,
		};

		return mappedData;
	});
};

export default getMyAdverts;
