import React from 'react';
import { Field } from 'formik';

const Website = ({ errors, touched }) => (
	<div className="space-y-2">
		Advert Website
		<span
			className="pl-2 text-sm text-gray-500"
			id="advert-website-heading"
		>
			(Optional)
		</span>
		<div className="my-1 text-xs text-gray-400">
			A link to your website so that visitors can redeem this offer
		</div>
		<Field
			id="offer_redemption_url"
			name="offer_redemption_url"
			placeholder="e.g. https://www.mypage.com/offer50"
			type="text"
			className="w-full p-2 border rounded"
		/>
		{errors.offer_redemption_url && touched.offer_redemption_url ? (
			<div className="p-2 my-2 bg-red-200 border border-red-400 rounded">
				{errors.offer_redemption_url}
			</div>
		) : null}
	</div>
);

export default Website;
