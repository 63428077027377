import http from 'utils/http';
import config from 'utils/config';

const uploadImage = async (payload, onProgress) => {
	const locale = payload.get('locale');
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/upload`;

	return http
		.post(uri, { data: payload, onUploadProgress: { onProgress } })
		.then((data) => data);
};

export default uploadImage;
