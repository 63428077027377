import React from 'react';
import { Field } from 'formik';

const Title = ({
	setHasTitle,
	validateForm,
	setFieldValue,
	values,
	errors,
}) => (
	<>
		<div className="space-y-2">
			<div id="advert-title-heading" className="flex items-center">
				<p>Title</p>
			</div>
			<Field
				id="offer_promotional_text"
				data-testid="offer-title"
				name="offer_promotional_text"
				placeholder="Example: 15% off for Bright Customers"
				type="text"
				as="textarea"
				maxLength="60"
				className="w-full p-2 border rounded"
				onKeyUp={(val) => {
					setHasTitle(val.target.value.length > 0);
					setFieldValue('offer_promotional_text', val.target.value);
				}}
				onBlur={(val) => {
					validateForm();
					setFieldValue('offer_promotional_text', val.target.value);
				}}
			/>
			<p
				className={
					values.offer_promotional_text.length === 60
						? 'text-red-500 text-xs mt-2'
						: 'text-gray-400 text-xs mt-2'
				}
			>
				Characters remaining:{' '}
				{60 - values.offer_promotional_text.length}
				/60
			</p>
			{errors.offer_promotional_text && (
				<p className="text-error-500">
					{errors.offer_promotional_text}
				</p>
			)}
		</div>
	</>
);

export default Title;
