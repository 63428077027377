import React from 'react';

export type IEFlagProps = React.SVGAttributes<SVGElement> & {
	className?: string;
};

export const IEFlag = ({ className }: IEFlagProps): React.ReactElement => (
	<svg
		width="32"
		height="24"
		viewBox="0 0 64 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		aria-hidden="true"
	>
		<path d="M64 0H0V48H64V0Z" fill="white" />
		<path d="M21.4 0H0V48H21.4V0Z" fill="#009A48" />
		<path d="M64 0H42.6V48H64V0Z" fill="#EF781A" />
	</svg>
);

export default IEFlag;
