import React from 'react';
import CategorySelector from '../../../components/category_selector';

const AdvertCategory = ({
	availableCategories,
	selectedCategory,
	setSelectedCategory,
	handleChange,
}) => (
	<>
		<div className="space-y-2">
			<p id="advert-category-heading">Advert Category</p>
			<CategorySelector
				id="offer_category"
				name="offer_category"
				defaultText="Select a Category"
				availableCategories={availableCategories}
				defaultSelection={selectedCategory.value}
				className="rounded border"
				onChange={(e) => {
					const index = e.nativeEvent.target.selectedIndex;
					setSelectedCategory({
						value: e.target.value,
						text: e.nativeEvent.target[index].text,
					});
					handleChange(e);
				}}
			/>
		</div>
	</>
);

export default AdvertCategory;
