import React from 'react';

const NoCurrentAdverts = () => (
	<div className="flex p-12 justify-center">
		<p className="flex justify-center text-center">
			You have no active adverts. <br />
			Create one by clicking the &apos;New Advert&apos; button above.
		</p>
	</div>
);
export default NoCurrentAdverts;
