import React from 'react';

const Standard = ({ advert }) => (
	<div className="flex flex-col flex-auto h-full duration-100 transform bg-white border rounded-lg transition-transform-shadow">
		<div className="flex items-start p-4 space-x-4">
			<div className="flex-none bg-white border border-gray-300 rounded shadow-lg h-14 w-14">
				<img
					className="object-cover w-full h-full"
					src={`${advert.advertiser_logo}?fit=crop&h=60&w=60`}
					alt={advert.advertiser_name}
				/>
			</div>
			<div className="flex-grow overflow-hidden">
				<h3 className="text-xl font-bold truncate">
					{advert.advertiser_name}
				</h3>
				<p className="inline-block px-3 py-1 text-xs text-gray-500 bg-gray-100 rounded-full">
					{advert.offer_category}
				</p>
			</div>
		</div>
		<div className="h-56">
			<img
				className="object-cover w-full h-full"
				src={`${advert.hero}?h=224`}
				alt={advert.advertiser_name}
			/>
		</div>
		<div className="flex flex-col flex-grow p-4">
			<p className="flex-auto pb-6 text-gray-500">
				{advert.offer_promotional_text}
			</p>
			<div className="flex items-end justify-between space-x-8 overflow-hidden">
				{advert.offer_is_percentage_discount &&
					advert.discount_text.length !== 0 && (
						<div className="flex flex-col justify-end flex-auto text-right">
							<p className="leading-none text-gray-500">
								Save up to
							</p>
							<p className="flex-auto text-xl font-bold truncate xl:text-2xl">
								{advert.discount_text}
								<span>%</span>
							</p>
						</div>
					)}

				{!advert.offer_is_percentage_discount && (
					<p className="flex-auto text-xl font-bold text-right truncate xl:text-2xl">
						{advert.discount_text}
					</p>
				)}
			</div>
		</div>
	</div>
);

export default Standard;
