import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

export default () => {
	ReactGA.pageview(window.location.pathname + window.location.search);

	if (ReactGA4.isInitialized) {
		ReactGA4.send({
			hitType: 'pageview',
			page: `${window.location.pathname + window.location.search}`,
		});
	}
};
