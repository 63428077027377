import { useState } from 'react';
import { Location } from 'react-router-dom';
import { LocaleState } from '../../context/LocaleContext/LocaleState';
import localeObject, {
	defaultLocaleObject,
} from '../../context/LocaleContext/localeObject';

function useLocaleContextValue(location: Location, navigate): LocaleState {
	// look in the url for a locale and return the country code
	// from the object matches the url locale on the LocaleObject
	const pathLocale = () => {
		const localeRegex = /\/(en|au|nz|ie|ca)(?:\/|$)/i;
		const matches = location.pathname.match(localeRegex);
		const map =
			matches &&
			Object.values(localeObject).find(
				({ countryCode }) => countryCode === matches[1]
			);

		return map ? map.countryCode : null;
	};

	// set the new locale in local storage
	// do a full page refresh and redirect to the new locale url
	const setNewLocale = (newLocale, refresh = true, isLanding = false) => {
		localStorage.setItem('preferredLocale', newLocale);
		setLocale(newLocale);

		if (refresh) {
			const localeRegex = /\/(en|au|nz|ie|ca)(?:\/|$)/i;
			const matches = location.pathname.match(localeRegex);

			let pathNameWithLocaleStripped: string | null = '';

			if (!isLanding) {
				pathNameWithLocaleStripped =
					stripLocaleFromUrlIfExists(matches) ||
					stripLocaleFromLocationPathnameIfExists();
			}

			navigate(
				`/${
					newLocale === 'en'
						? `${pathNameWithLocaleStripped}`
						: `${newLocale}/${pathNameWithLocaleStripped}`
				}`
			);
		}

		function stripLocaleFromUrlIfExists(matches: RegExpMatchArray | null) {
			return matches && location.pathname.replace(`${matches[0]}`, '');
		}

		function stripLocaleFromLocationPathnameIfExists(): string | null {
			return location.pathname.substring(1, location.pathname.length);
		}
	};

	// get the locale from local storage
	const lsLocale = localStorage.getItem('preferredLocale');

	// get the preferredLocale based on the following order of priority:
	//	url
	//	local storage
	//	default to 'en' if both previous steps are null
	const [preferredLocale, setLocale] = useState(
		pathLocale() || lsLocale || 'en'
	);

	const currentLocale =
		localeObject[pathLocale() || lsLocale || 'en'] || defaultLocaleObject;

	const { languageCode } =
		localeObject[pathLocale() || lsLocale || 'en'] || defaultLocaleObject;

	return {
		preferredLocale,
		setNewLocale,
		lsLocale,
		currentLocale,
		languageCode,
	};
}

export default useLocaleContextValue;
