const BRIGHT_SESSION = 'sessionId';
const guid = () => {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}
	return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

// clean out old values which used BrightSession, to be removed in the future
if (
	(window.localStorage.getItem(BRIGHT_SESSION) || '').indexOf('data') !== -1
) {
	window.localStorage.removeItem(BRIGHT_SESSION);
}

const sessionId = window.localStorage.getItem(BRIGHT_SESSION) || guid();
window.localStorage.setItem(BRIGHT_SESSION, sessionId);

export default sessionId;
