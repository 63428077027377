import http from 'utils/http';
import config from 'utils/config';

const getRegions = (locale) => {
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/region`;

	return http.get(uri).then(({ data }) => data.data.allRegions);
};

export default getRegions;
