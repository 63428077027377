import React from 'react';
import { Link } from 'react-router-dom';
import recordInteraction from 'services/telemetry';
import { useLocaleContext } from 'context/LocaleContext';

const CancelCreateLink = () => {
	const { languageCode } = useLocaleContext();

	return (
		<Link
			id="cancel-create-advert"
			to="/groups"
			onClick={() => {
				recordInteraction(
					{
						action: 'cancel create advert',
					},
					languageCode
				);
			}}
		>
			<p className="underline mb-4 ml-2 font-bold text-blue-700 hover:text-blue-800">
				Back to Marketplace
			</p>
		</Link>
	);
};

export default CancelCreateLink;
