import React from 'react';
import Uploader from 'pages/components/uploader';
import uploadImage from 'services/uploadImage';

const CompanyLogo = ({
	errors,
	brightCompanyDetails,
	uploadError,
	setUploadError,
	advertiserImage,
	setAdvertiserImage,
	setFieldValue,
	validateForm,
	advertId,
	companyProfile,
	locale,
}) => (
	<>
		<div className="flex items-start pb-4 space-x-4">
			<div className="w-16 h-16">
				<Uploader
					type="thumbnail"
					onError={setUploadError}
					uploadService={uploadImage}
					locale={locale}
					onUploaded={(data) => {
						setAdvertiserImage(data);
						setFieldValue('advertiser_logo', data.id);
						validateForm();
					}}
					initialImageUrl={
						advertId
							? advertiserImage.url
							: companyProfile.companyLogoUrl
					}
				/>
			</div>

			<div className="p-2 font-bold">
				{brightCompanyDetails.name}
				{errors.advertiser_logo && (
					<p className="font-normal text-error-500">
						{errors.advertiser_logo}
					</p>
				)}
			</div>
		</div>
		{uploadError && (
			<p className="font-normal text-error-500">
				There was a problem with this upload.
			</p>
		)}
	</>
);

export default CompanyLogo;
