import jwtDecode from 'jwt-decode';

let decodedToken;

const GetToken = () => {
	if (!window.localStorage.getItem('userToken')) {
		return {};
	}

	if (!decodedToken) {
		decodedToken = jwtDecode(window.localStorage.getItem('userToken'));
	}
	return decodedToken;
};

export default GetToken;
