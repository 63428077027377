import React from 'react';

export type AUFlagProps = React.SVGAttributes<SVGElement> & {
	className?: string;
};

export const AUFlag = ({ className }: AUFlagProps): React.ReactElement => (
	<svg
		width="32"
		height="24"
		viewBox="0 0 64 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		aria-hidden="true"
	>
		<path d="M64 0H0V48H64V0Z" fill="#202979" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M28.98 39.25L24.61 39.63L25.21 43.97L22.19 40.8L19.17 43.97L19.78 39.63L15.41 39.25L19.18 37.02L16.75 33.37L20.85 34.92L22.19 30.75L23.54 34.92L27.63 33.37L25.21 37.02L28.98 39.25Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M47.65 42.2499L46.6 40.6699L48.38 41.3399L48.96 39.5299L49.54 41.3399L51.33 40.6699L50.27 42.2499L51.91 43.2199L50.01 43.3899L50.27 45.2699L48.96 43.8999L47.65 45.2699L47.91 43.3899L46.02 43.2199L47.65 42.2499Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M47.65 14.04L46.6 12.46L48.38 13.13L48.96 11.32L49.54 13.13L51.33 12.46L50.27 14.04L51.91 15.02L50.01 15.18L50.27 17.07L48.96 15.69L47.65 17.07L47.91 15.18L46.02 15.02L47.65 14.04Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M41.7701 24.5L40.7201 22.92L42.5 23.59L43.08 21.78L43.6701 23.59L45.45 22.92L44.39 24.5L46.03 25.4799L44.13 25.6399L44.4 27.5299L43.08 26.1499L41.7701 27.5299L42.03 25.6399L40.14 25.4799L41.7701 24.5Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M57.06 22.6799L56 21.0999L57.78 21.7699L58.36 19.9599L58.95 21.7699L60.73 21.0999L59.68 22.6799L61.31 23.6499L59.41 23.8199L59.68 25.6999L58.36 24.3199L57.05 25.6999L57.31 23.8199L55.4201 23.6499L57.06 22.6799Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M53.1901 30.99L52.16 31.64L52.4501 30.45L51.5201 29.67L52.73 29.5799L53.1901 28.45L53.6501 29.5799L54.8701 29.67L53.9401 30.45L54.23 31.64L53.1901 30.99Z"
			fill="white"
		/>
		<path
			d="M4.05 0L0 0.0001V0V3.0375L31.95 27H36V23.9625L4.05 0Z"
			fill="white"
		/>
		<path d="M31.95 0L0 23.9625V27H4.05L36 3.0375V0H31.95Z" fill="white" />
		<path d="M20.9812 0H15.0187V27H20.9812V0Z" fill="white" />
		<path d="M36 9H0V18H36V9Z" fill="white" />
		<path d="M36 10.8H0V16.2H36V10.8Z" fill="#CC1517" />
		<path d="M19.8 0H16.2V27H19.8V0Z" fill="#CC1517" />
		<path d="M12 18L0 27L2.6832 26.9999L14.6833 18H12Z" fill="#CC1517" />
		<path d="M0 0V2.0124L9.3168 8.9999L12 9L0 0Z" fill="#CC1517" />
		<path
			d="M33.3169 0L21.3168 9L24.0001 8.9999L36.0001 0H33.3169Z"
			fill="#CC1517"
		/>
		<path d="M36 27L24 18H26.6832L36 24.9875V27Z" fill="#CC1517" />
	</svg>
);

export default AUFlag;
