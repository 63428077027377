import React, { useState, useEffect, useRef, useCallback } from 'react';
import cn from 'classnames';
import Avatar from '@brighthr/component-avatar';
import Icon from '@brighthr/component-icon';

export type ContextMenuProps = React.HTMLAttributes<HTMLDivElement> & {
	isAdmin?: boolean;
	company?: string;
	companyLogoUrl?: string;
};

export type NavItemProps = {
	text: string;
	href?: string;
	icon?: React.ReactNode;
	openNewWindow?: boolean;
	className?: string;
};

const NavItem = ({
	href,
	text,
	icon,
	openNewWindow,
	className,
}: NavItemProps) => (
	<li
		className={cn(
			'border-b-2 p-4 border-2 border-transparent rounded-md',
			'focus-within:border-solid focus-within:border-2 focus-within:border-blue-700',
			className
		)}
	>
		{href ? (
			<a
				href={href}
				target={openNewWindow ? '_blank' : undefined}
				className={cn('flex items-center text-blue-700 outline-none')}
				rel="noreferrer"
			>
				{icon && (
					<span className={cn('flex items-center mr-4')} aria-hidden>
						{icon}
					</span>
				)}
				<span>{text}</span>
			</a>
		) : (
			<div className={cn('flex items-center text-blue-700 outline-none')}>
				{icon && (
					<span className={cn('flex items-center mr-3')} aria-hidden>
						{icon}
					</span>
				)}
				<span>{text}</span>
			</div>
		)}
	</li>
);

export const ContextMenu = ({
	isAdmin,
	company,
	className,
	companyLogoUrl,
}: ContextMenuProps) => {
	const contextMenuButtonRef = useRef<HTMLButtonElement>(null);
	const contextMenuRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		setIsOpen(!isOpen);
		event.preventDefault();
	};

	const closeContextMenu = (focusRef: React.RefObject<HTMLButtonElement>) => {
		setIsOpen(false);
		focusRef?.current?.focus();
	};

	const handleKeyDown = useCallback((event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			closeContextMenu(contextMenuButtonRef);
			event.preventDefault();
		}
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleKeyDown]);

	return (
		<div ref={contextMenuRef} className={cn(className, 'relative')}>
			<button
				type="button"
				ref={contextMenuButtonRef}
				onClick={(event) => handleClick(event)}
				aria-label={`${isOpen ? 'Close' : 'Open'} Account Menu`}
				aria-expanded={isOpen}
				className={cn(
					'flex border-2 border-gray-300 py-2 px-6 rounded-full'
				)}
			>
				<span
					className={cn(
						'flex justify-center items-center max-w-none'
					)}
				>
					<Avatar
						name={company}
						imageHref={companyLogoUrl}
						size="xs"
					/>
					{isOpen ? (
						<Icon
							iconName="chevron-thin-up"
							size="xs"
							className={cn('ml-2')}
						/>
					) : (
						<Icon
							iconName="chevron-thin-down"
							size="xs"
							className={cn('ml-2')}
						/>
					)}
				</span>
			</button>
			{isOpen && (
				<nav
					className={cn(
						'absolute right-0 my-3 border-2 border-gray-300 rounded-md z-60 w-80'
					)}
					aria-label="Account"
					onMouseLeave={() => setIsOpen(false)}
				>
					<ul
						className={cn(
							'relative flex flex-col bg-white rounded-md '
						)}
					>
						<NavItem
							text={company || 'Profile'}
							icon={
								<Avatar
									name={company}
									imageHref={companyLogoUrl}
									size="sm"
								/>
							}
						/>
						{isAdmin && (
							<NavItem
								href="/admin_portal"
								text="My Adverts"
								icon={
									<Icon
										iconName="user"
										size="base"
										className="text-blue-400 fill-current"
									/>
								}
							/>
						)}
						<NavItem
							href="https://app.brighthr.com/dashboard"
							openNewWindow
							text="Go to BrightHR"
							icon={
								<Icon
									iconName="xing"
									size="base"
									className="text-blue-400 fill-current"
								/>
							}
						/>
						<NavItem
							href="/logout"
							text="Log out"
							icon={
								<Icon
									iconName="power"
									size="base"
									className="text-blue-400 fill-current"
								/>
							}
						/>
					</ul>
				</nav>
			)}
		</div>
	);
};

export default ContextMenu;
