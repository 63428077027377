import React from 'react';
import classNames from 'classnames';

import { Tag } from 'services/getTags';

const Tags = ({
	tags,
	offerTag,
	setFieldValue,
}: {
	tags: Tag[];
	offerTag: string;
	setFieldValue: any;
}) => {
	const CheckboxTag = ({
		id,
		tag,
		index,
	}: {
		id: string;
		tag: string;
		index: number;
	}) => {
		const checked = tags[index].tag === offerTag;

		return (
			<label
				htmlFor={`tag-${id}`}
				className={classNames(
					'flex justify-center items-center border-2 border-opacity-10 border-neutral-500 px-4 py-2 my-1 rounded-full cursor-pointer',
					{
						'bg-primary-700 bg-opacity-10': checked,
						'bg-white': !checked,
					}
				)}
			>
				<input
					id={`tag-${id}`}
					name="tag"
					type="radio"
					checked={checked}
					className="opacity-0 absolute"
					onChange={(event) => {
						if (event.target.checked) {
							setFieldValue('offer_tag', tags[index].tag);
						}
					}}
				/>
				{tag}
			</label>
		);
	};

	return (
		<div className="space-y-2">
			Tags
			<span
				className="pl-2 text-sm text-gray-500"
				id="advert-website-heading"
			>
				(Optional)
			</span>
			<div className="my-1 text-xs text-gray-400">
				Select the advert tag
			</div>
			<div className="flex flex-wrap space-x-2">
				{tags?.map(({ id, tag }, index) => (
					<CheckboxTag id={id} tag={tag} key={id} index={index} />
				))}
			</div>
		</div>
	);
};

export default Tags;
