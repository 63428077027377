import React from 'react';

const Requirement = ({ isSatisfied, requirementText }) => (
	<li className="flex space-x-2 items-center">
		<span
			className={`${
				isSatisfied ? 'block text-green-500 leading-none' : 'hidden'
			}`}
		>
			&#10003;
		</span>
		<p className={`${isSatisfied ? 'font-bold text-gray-900' : ''}`}>
			Advert has {requirementText}
		</p>
	</li>
);

export default Requirement;
