import React from 'react';
import { Field } from 'formik';

const CompanyAboutUs = ({
	values,
	errors,
	setFieldValue,
	companyProfile,
	setCompanyProfile,
	validateForm,
}) => (
	<div className="space-y-2">
		<p id="company-profile-about-us">About Us</p>
		<Field
			id="advertiser_about_us"
			data-testid="about-us"
			name="advertiser_about_us"
			placeholder="Tell visitors a little bit about your company. What makes you stand out?"
			type="text"
			as="textarea"
			maxLength="400"
			className="rounded border p-2 w-full h-32"
			onBlur={(val) => {
				validateForm();
				setFieldValue('advertiser_about_us', val.target.value);
				const updatedProfile = companyProfile;
				updatedProfile.about_us = val.target.value;
				setCompanyProfile(updatedProfile);
			}}
		/>
		<p
			className={
				values.advertiser_about_us.length === 400
					? 'text-red-500 text-xs'
					: 'text-gray-400 text-xs'
			}
		>
			Characters remaining: {400 - values.advertiser_about_us.length}
			/400
		</p>
		{errors.advertiser_about_us && (
			<p className="text-error-500">{errors.advertiser_about_us}</p>
		)}
	</div>
);

export default CompanyAboutUs;
