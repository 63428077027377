import React, {
	ReactNode,
	useState,
	useEffect,
	useContext,
	createContext,
} from 'react';

type ViewportContextProps = {
	width: number;
	height: number;
	isLargeViewport: boolean;
};

export type ViewportProviderProps = {
	children?: ReactNode;
};

export const ViewportContext = createContext<ViewportContextProps>({
	width: 0,
	height: 0,
	isLargeViewport: false,
});

export const ViewportProvider = (props: ViewportProviderProps) => {
	const largeViewportBreakpoint = 1024;
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [height, setHeight] = useState<number>(window.innerHeight);
	const [isLargeViewport, setIsLargeViewport] = useState<boolean>(
		window.innerWidth > largeViewportBreakpoint
	);

	const handleWindowResize = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
		setIsLargeViewport(window.innerWidth > largeViewportBreakpoint);
	};

	useEffect(() => {
		handleWindowResize();
	}, []);

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, [width, height]);

	return (
		<ViewportContext.Provider value={{ width, height, isLargeViewport }}>
			{props.children}
		</ViewportContext.Provider>
	);
};

export const useViewportContext = () => {
	const viewportContext = useContext(ViewportContext);

	if (!viewportContext) {
		throw new Error('Viewport context needs to be wrapped in provider');
	}

	const { width, height, isLargeViewport } = viewportContext;
	return { width, height, isLargeViewport };
};

export default ViewportProvider;
