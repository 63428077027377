import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, ModalBody } from '@brighthr/component-modal';
import { useLocaleContext } from 'context/LocaleContext';
import localeObject from 'context/LocaleContext/localeObject';

const LocaleModal = () => {
	const [showModal, setShowModal] = useState(false);
	const [ipLocale, setIpLocale] = useState();

	const { setNewLocale, lsLocale, currentLocale } = useLocaleContext();

	useEffect(() => {
		if (lsLocale) return;
		// Get the current users countryCode from geo-ip which determines whether we show the modal or not
		return axios
			.get('https://geo-ip.netlify.app/locale.json', {
				json: true,
			})
			.then(({ data: { countryCodeLower } }) => {
				const newIpLocale = localeObject[countryCodeLower];

				if (!lsLocale && currentLocale.countryCode === countryCodeLower)
					setNewLocale(currentLocale.countryCode, false);

				setIpLocale(newIpLocale);
				setShowModal(
					newIpLocale &&
						currentLocale.countryCode !== countryCodeLower
				);
			})
			.catch(() => {});
	}, [currentLocale.countryCode, lsLocale, setNewLocale]);

	return showModal ? (
		<Modal width="sm" close={() => {}}>
			<ModalBody className="m-2 text-center">
				<p className="mx-4 my-2 bg-white">
					It looks like you’re visiting from{' '}
					{ipLocale?.name ? ipLocale?.name : 'another location'}.
					Would you like to visit the {ipLocale?.nameShort} site?
				</p>
				<div className="mb-4">
					<button
						type="button"
						className="px-4 py-2 font-bold text-white bg-pink-500 cursor-pointer rounded-xl hover:bg-pink-700"
						onClick={() => {
							if (ipLocale?.countryCode) {
								setNewLocale(ipLocale?.countryCode);
							}
							setShowModal(false);
						}}
					>
						Yes, take me to the {ipLocale?.name} site
					</button>
				</div>

				<div>
					<button
						type="button"
						className="font-bold text-blue-700 hover:text-blue-800"
						onClick={() => {
							setNewLocale(currentLocale?.countryCode, false);
							setShowModal(false);
						}}
					>
						No, stay on the {currentLocale?.name} site
					</button>
				</div>
			</ModalBody>
		</Modal>
	) : null;
};

export default LocaleModal;
