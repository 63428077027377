import React from 'react';

export type UKFlagProps = React.SVGAttributes<SVGElement> & {
	className: string;
};

export const UKFlag = ({ className }: UKFlagProps): React.ReactElement => (
	<svg
		width="32"
		height="24"
		viewBox="0 0 64 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
		aria-hidden="true"
	>
		<path d="M64 0H0V48H64V0Z" fill="#202979" />
		<path d="M7.2 0L0 0.0002V0V5.4L56.8 48H64V42.6L7.2 0Z" fill="white" />
		<path d="M56.8 0L0 42.6V48H7.2L64 5.4V0H56.8Z" fill="white" />
		<path d="M37.2999 0H26.7V48H37.2999V0Z" fill="white" />
		<path d="M64 16H0V32H64V16Z" fill="white" />
		<path d="M64 19.2H0V28.8H64V19.2Z" fill="#CC1517" />
		<path d="M35.2 0H28.8V48H35.2V0Z" fill="#CC1517" />
		<path
			d="M21.3333 32L0 48L4.7701 47.9998L26.1035 32H21.3333Z"
			fill="#CC1517"
		/>
		<path
			d="M0 0V3.5777L16.5632 15.9998L21.3333 15.9999L0 0Z"
			fill="#CC1517"
		/>
		<path
			d="M59.2299 0L37.8965 15.9999L42.6667 15.9998L64 0H59.2299Z"
			fill="#CC1517"
		/>
		<path
			d="M42.6667 32L64 48V44.4223L47.4369 32H42.6667Z"
			fill="#CC1517"
		/>
	</svg>
);

export default UKFlag;
