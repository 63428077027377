import React, { useState } from 'react';
import MiniAdvert from '../../../styles/images/mini_listing_icon.svg';
import Offer from '../../../styles/images/offer_icon.svg';
import Percentage from '../../../styles/images/new_percentage_icon.svg';
import MiniAdvertWhite from '../../../styles/images/mini_listing_icon_white.svg';
import OfferWhite from '../../../styles/images/offer_icon_white.svg';
import PercentageWhite from '../../../styles/images/new_percentage_icon_white.svg';

const AdvertTypeSelector = ({
	validateForm,
	defaultValue,
	defaultSelection,
	OnOfferTextChanged,
	OnOfferTypeChanged,
	isNoOffer,
	setIsNoOffer,
}) => {
	const [isPercentage, setIsPercentage] = useState(defaultSelection);
	return (
		<>
			<div className="flex space-x-2">
				<button
					type="button"
					className={`w-1/2 md:w-1/3 border rounded p-2 cursor-pointer flex flex-col items-center justify-end ${
						isNoOffer ? 'bg-blue-700 text-white' : ''
					}`}
					onClick={() => {
						setIsPercentage(false);
						OnOfferTypeChanged(false);
						OnOfferTextChanged('');
						setIsNoOffer(true);
					}}
				>
					<img
						className="w-8"
						src={isNoOffer ? MiniAdvertWhite : MiniAdvert}
						alt="alt"
					/>

					<p className="mt-2 text-xs">No offer</p>
				</button>

				<button
					type="button"
					data-testid="percentage-offer-select"
					className={`w-1/2 md:w-1/3 border rounded p-2 cursor-pointer flex flex-col items-center justify-end ${
						isPercentage ? 'bg-blue-700 text-white' : ''
					}`}
					onClick={() => {
						setIsPercentage(true);
						setIsNoOffer(false);
						OnOfferTypeChanged(true);
					}}
				>
					<img
						className="w-9"
						src={isPercentage ? PercentageWhite : Percentage}
						alt="alt"
					/>

					<p className="mt-2 text-xs">Percentage Discount</p>
				</button>
				<button
					type="button"
					className={`w-1/2 md:w-1/3 border rounded p-2 cursor-pointer flex flex-col items-center justify-end ${
						isPercentage || isNoOffer
							? ''
							: 'bg-blue-700 text-white'
					}`}
					onClick={() => {
						setIsPercentage(false);
						setIsNoOffer(false);
						OnOfferTypeChanged(false);
					}}
				>
					<img
						className="w-9"
						src={isPercentage || isNoOffer ? Offer : OfferWhite}
						alt="alt"
					/>

					<p className="mt-2 text-xs">Special Offer</p>
				</button>
			</div>

			{!isNoOffer && (
				<>
					<div className="text-xs text-gray-400">
						{isPercentage
							? 'How much of a percentage is the discount?'
							: 'Briefly describe the special offer'}
					</div>
					<input
						className="w-full p-2 border rounded"
						type={isPercentage ? 'number' : 'text'}
						min="1"
						max="99"
						onBlur={() => {
							validateForm();
						}}
						maxLength="25"
						value={defaultValue}
						placeholder={
							isPercentage
								? 'Example : 15'
								: 'Example : Buy one get one free!'
						}
						onChange={(val) => {
							OnOfferTextChanged(val.target.value);
						}}
						onKeyDown={(e) =>
							isPercentage &&
							(e.key === 'e' ||
								e.key === 'E' ||
								e.key === '-' ||
								e.key === '+') &&
							e.preventDefault()
						}
					/>
				</>
			)}
		</>
	);
};

export default AdvertTypeSelector;
