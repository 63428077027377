import React from 'react';
import { Field } from 'formik';
import AdvertTypeSelector from '../../../components/advert_type_selector';

const OfferType = ({
	values,
	errors,
	validateForm,
	setFieldValue,
	isNoOffer,
	setIsNoOffer,
	isPercentageDiscount,
	setIsPercentageDiscount,
	setDiscountText,
	setHasDiscountType,
}) => (
	<div className="space-y-2">
		<div className="space-y-2">
			<p id="advert-discount-type-heading">Offer Type</p>
			<AdvertTypeSelector
				validateForm={validateForm}
				defaultValue={values.discount_text}
				defaultSelection={isPercentageDiscount}
				isNoOffer={isNoOffer}
				setIsNoOffer={setIsNoOffer}
				OnOfferTypeChanged={(isPercentage) => {
					setFieldValue('offer_is_percentage_discount', isPercentage);
					setIsPercentageDiscount(isPercentage);
					setDiscountText('');
				}}
				OnOfferTextChanged={(offerText) => {
					setFieldValue('discount_text', offerText);
					setHasDiscountType(offerText.length > 0);
					setDiscountText(offerText);
				}}
			/>
			{!isPercentageDiscount && !isNoOffer && (
				<p
					className={
						values.discount_text.length === 25
							? 'text-red-500 text-xs mt-2'
							: 'text-gray-400 text-xs mt-2'
					}
				>
					Characters remaining: {25 - values.discount_text.length}
					/25
				</p>
			)}
			{errors.discount_text && (
				<p className="text-error-500">{errors.discount_text}</p>
			)}
		</div>
		{!isNoOffer && (
			<>
				<div className="flex items-center">
					Redemption Text
					<span
						className="flex items-center pl-2 text-sm text-gray-500"
						id="advert-redemptiontext-heading"
					>
						(Optional)
					</span>
				</div>
				<Field
					id="offer_redemption_instructions"
					data-testid="redemption-text"
					name="offer_redemption_instructions"
					placeholder="Are there any steps necessary to get this offer?"
					type="text"
					as="textarea"
					maxLength="850"
					className="w-full p-2 border rounded"
					onKeyUp={(e) => {
						setFieldValue(
							'offer_redemption_instructions',
							e.target.value
						);
					}}
				/>
				<p
					className={
						values.offer_redemption_instructions.length === 850
							? 'text-red-500 text-xs mt-2'
							: 'text-gray-400 text-xs mt-2'
					}
				>
					Characters remaining:{' '}
					{850 - values.offer_redemption_instructions.length}
					/850
				</p>

				<div>
					Promo Code
					<span
						className="pl-2 text-sm text-gray-500"
						id="advert-prmocode-heading"
					>
						(Optional)
					</span>
					<Field
						id="offer_promo_code"
						data-testid="promo-code"
						name="offer_promo_code"
						placeholder="If there is promo code to use, enter it here"
						type="text"
						className="w-full p-2 border rounded"
					/>
				</div>
			</>
		)}
	</div>
);

export default OfferType;
