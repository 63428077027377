import createCompanyProfile from 'services/companyProfile/createCompanyProfile';
import updateCompanyProfile from 'services/companyProfile/updateCompanyProfile';

const submitCompanyProfile = (values, locale) => {
	const profile = {
		aboutUs: values.advertiser_about_us,
		brightCompanyGuid: values.bright_company_guid,
		companyLogoId: values.advertiser_logo,
		companyName: values.advertiser_name,
		id: values.company_profile_id,
	};
	if (profile.id === '') {
		createCompanyProfile(profile, locale);
	} else {
		updateCompanyProfile(profile, locale);
	}
};

export default submitCompanyProfile;
