interface Props {
	selectedCategoryId: string;
	selectedRegionId: string;
	selectedAudienceId: string;
	searchTerm: string;
}

const buildFilter = (props: Props) => {
	const {
		selectedCategoryId,
		selectedRegionId,
		selectedAudienceId,
		searchTerm,
	} = props;

	return `?categoryId=${selectedCategoryId}&regionId=${selectedRegionId}&audience=${selectedAudienceId}&searchTerm=${searchTerm}`;
};

export default buildFilter;
