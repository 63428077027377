import Spinner from '@brighthr/component-spinner';

import React, { useState, useRef } from 'react';

const Uploader = ({
	onUploaded,
	onError,
	uploadService,
	type = 'button',
	initialImageUrl = '',
	existingFilename,
	locale,
}) => {
	const [imagePreviewUrl, setImagePreviewUrl] = useState(initialImageUrl);
	const [selectedFile, setSelectedFile] = useState(null);
	const [imageUploading, setImageUploading] = useState(false);
	const fileInput = useRef(null);
	const onImageChange = (e) => {
		if (e.target.files) {
			const fileToUpload = e.target.files[0];
			if (fileToUpload) {
				setSelectedFile(fileToUpload);
				setImageUploading(true);
				setImagePreviewUrl(URL.createObjectURL(fileToUpload));

				const formData = new FormData();
				formData.append('image', fileToUpload);
				formData.append('locale', locale);
				Promise.resolve(uploadService(formData))
					.then(({ data }) => {
						setImageUploading(false);
						onUploaded(data);
					})
					.catch((error) => {
						console.log(error);
						onError(true);
					});
			}
		}
	};

	const renderDefaultText = () => {
		if (selectedFile) return selectedFile.name;
		if (initialImageUrl) return existingFilename;
		return 'No file chosen';
	};
	return (
		<>
			<input
				id="image_upload"
				ref={fileInput}
				type="file"
				onChange={onImageChange}
				className="hidden w-full p-2 border border-gray-200 rounded flex-right"
				accept="image/png, image/jpeg"
				aria-label="upload-button"
			/>
			{type === 'button' && (
				<>
					<div className="w-full p-2 border border-gray-200 rounded flex-right ">
						<button
							type="button"
							data-testid="upload-button"
							className="px-2 bg-gray-100 border border-black rounded hover:bg-gray-200 "
							onClick={() => fileInput.current.click()}
						>
							Choose file
						</button>
						<span className="pl-2">{renderDefaultText()}</span>
					</div>

					<div className="relative flex items-center content-center justify-center w-1/2 m-auto align-middle">
						{imageUploading && (
							<Spinner
								size="base"
								className="absolute stroke-white z-10 object-center"
								ariaLabel="uploading file"
							/>
						)}
						<img
							alt=""
							src={imagePreviewUrl ?? ''}
							className={`${imageUploading ? 'filter blur' : ''}`}
						/>
					</div>
				</>
			)}

			{type === 'thumbnail' && (
				<div className="h-full border border-gray-200 rounded hover:border-gray-300">
					<button
						type="button"
						onClick={() => fileInput.current.click()}
						title="Click to pick an image"
						className="relative w-full h-full group"
					>
						<div className="absolute bottom-0 right-0 z-20 flex items-center justify-center w-8 h-8 p-1 text-blue-800 transform translate-x-4 translate-y-3 bg-white border border-gray-300 rounded-full shadow-lg group-hover:bg-blue-100">
							<svg
								width="32"
								height="32"
								viewBox="0 0 32 32"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="fill-current"
							>
								<path d="M28 9.5C28 6.487 25.512 4 22.5 4C21.038 4 19.637 4.575 18.613 5.612L5.463 18.762C4.513 19.712 4 20.962 4 22.3V27C4 27.55 4.45 28 5 28H9.713C11.051 28 12.301 27.475 13.25 26.538L26.4 13.388C27.425 12.363 28 10.963 28 9.5ZM11.825 25.125C11.262 25.688 10.5 26 9.7 26H6V22.288C6 21.488 6.313 20.738 6.875 20.163L16.813 10.225L21.763 15.175L11.825 25.125ZM24.975 11.975L23.187 13.762L18.237 8.812L20.025 7.025C20.675 6.375 21.575 6 22.5 6C24.425 6 26 7.575 26 9.5C26 10.425 25.625 11.325 24.975 11.975Z" />
							</svg>
						</div>
						<div className="absolute top-0 left-0 z-10 flex items-center content-center justify-center w-full h-full m-auto align-middle">
							{imageUploading && (
								<Spinner
									size="base"
									className="absolute z-10 object-center stroke-white"
									ariaLabel="loading"
								/>
							)}
							<img
								alt="thumnail"
								src={
									selectedFile
										? `${imagePreviewUrl}`
										: initialImageUrl
								}
							/>
						</div>
					</button>
				</div>
			)}
		</>
	);
};

export default Uploader;
