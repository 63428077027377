const localeObject = {
	en: {
		countryCode: 'en',
		languageCode: 'en-gb',
		nameShort: 'UK',
		name: 'UK',
	},
	au: {
		countryCode: 'au',
		languageCode: 'en-au',
		nameShort: 'AU',
		name: 'Australia',
	},
	nz: {
		countryCode: 'nz',
		languageCode: 'en-nz',
		nameShort: 'NZ',
		name: 'New Zealand',
	},
	ie: {
		countryCode: 'ie',
		languageCode: 'en-ie',
		nameShort: 'IE',
		name: 'Ireland',
	},
	ca: {
		countryCode: 'ca',
		languageCode: 'en-ca',
		nameShort: 'CA',
		name: 'Canada',
	},
};

export const defaultLocaleObject = {
	countryCode: 'en',
	languageCode: 'en-gb',
	nameShort: 'UK',
	name: 'UK',
};

export default localeObject;
