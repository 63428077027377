/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-void */
/* eslint-disable no-multi-assign */
/* eslint-disable prefer-const */

import { useEffect } from 'react';

export default function useDrift() {
	useEffect(() => {
		let t = (window.driftt = window.drift = window.driftt || []);
		if (!t.init) {
			if (t.invoked)
				return void (
					window.console &&
					console.error &&
					console.error('Drift snippet included twice.')
				);
			(t.invoked = !0),
				(t.methods = [
					'identify',
					'config',
					'track',
					'reset',
					'debug',
					'show',
					'ping',
					'page',
					'hide',
					'off',
					'on',
				]),
				(t.factory = function (e) {
					return function () {
						let n = Array.prototype.slice.call(arguments);
						return n.unshift(e), t.push(n), t;
					};
				}),
				t.methods.forEach((e) => {
					t[e] = t.factory(e);
				}),
				(t.load = function (tt) {
					let e = 3e5;
					let n = Math.ceil(new Date() / e) * e;
					let o = document.createElement('script');
					(o.type = 'text/javascript'),
						(o.async = !0),
						(o.crossorigin = 'anonymous'),
						(o.src = `https://js.driftt.com/include/${n}/${tt}.js`);
					let i = document.getElementsByTagName('script')[0];
					i.parentNode.insertBefore(o, i);
				});
		}
		drift.SNIPPET_VERSION = '0.3.1';
		drift.load('25p9gk4dxncv');
	}, []);
}
/* eslint-enable no-undef */
/* eslint-enable prefer-rest-params */
/* eslint-enable no-sequences */
/* eslint-enable no-unused-expressions */
/* eslint-enable no-void */
/* eslint-enable no-multi-assign */
/* eslint-enable prefer-const */
/* eslint-enable func-names */
