import React, { useEffect, useState } from 'react';
import getProfile from 'services/companyProfile/getCompanyProfile';
import { Formik, Field, Form } from 'formik';
import uploadImage from 'services/uploadImage';
import createCompanyProfile from 'services/companyProfile/createCompanyProfile';
import updateCompanyProfile from 'services/companyProfile/updateCompanyProfile';
import { useLocaleContext } from 'context/LocaleContext';
import { CompactHeader as Header } from 'pages/components/compactHeader';
import validate from './utils/formikValidate';

const CompanyProfile = () => {
	const [profile, setProfile] = useState();
	const [loading, setLoading] = useState(true);
	const [image, setImage] = useState(null);
	const [imageUploading, setImageUploading] = useState(false);
	const [advertiserImage, setAdvertiserImage] = useState();

	const { languageCode } = useLocaleContext();

	useEffect(() => {
		getProfile(languageCode).then((apiResponse) => {
			setProfile(apiResponse);
			setImage(apiResponse.companyLogoUrl);
			setLoading(false);
		});
	}, [languageCode]);

	const onImageChange = (e, validateForm) => {
		if (e.target.files) {
			setImageUploading(true);
			setImage(URL.createObjectURL(e.target.files[0]));

			const formData = new FormData();
			formData.append('image', e.target.files[0]);
			Promise.resolve(uploadImage(formData, languageCode)).then(
				({ data }) => {
					setAdvertiserImage(data);
					setImageUploading(false);
					validateForm();
				}
			);
		}
	};

	return (
		<>
			<div className="h-screen bg-gray-50">
				<Header />
				<div className="h-screen bg-gray-50">
					{!loading && (
						<Formik
							initialValues={{
								id: profile.id,
								aboutUs: profile.aboutUs,
								companyLogoUrl: profile.companyLogoUrl,
								companyLogoId: profile.companyLogoId,
								companyName: profile.companyName,
								brightCompanyGuid: profile.brightCompanyGuid,
							}}
							validate={validate}
							validateOnChange={false}
							onSubmit={async (values) => {
								values.companyLogoId = advertiserImage
									? advertiserImage.id
									: profile.companyLogoId;
								if (values.id === '') {
									createCompanyProfile(values, languageCode);
								} else {
									updateCompanyProfile(values, languageCode);
								}
							}}
						>
							{(formik) => {
								const {
									setFieldValue,
									errors,
									values,
									validateForm,
								} = formik;
								return (
									<Form id="profile_form">
										<div className="border-b border-gray-200">
											<div className="max-w-screen-xl px-6 py-12 mx-auto space-y-12 lg:px-12 xl:max-w-screen-2xl lg:space-y-4">
												<h1 className="text-xl">
													Company Profile
												</h1>
											</div>
										</div>
										<div className="max-w-screen-xl px-6 py-12 mx-auto space-y-12 lg:px-12 xl:max-w-screen-2xl lg:space-y-4">
											<div className="lg:flex">
												<div className="lg:w-1/2">
													<p className="font-bold">
														Company Name
													</p>
												</div>
												<div className="lg:w-1/2">
													<Field
														id="companyName"
														name="companyName"
														placeholder={`${profile.companyName}`}
														type="text"
														maxLength="400"
														className="w-full p-2 border rounded"
														onBlur={(val) => {
															validateForm();
															setFieldValue(
																'companyName',
																val.target.value
															);
														}}
													/>
													{errors.companyName && (
														<p className="text-error-500">
															{errors.companyName}
														</p>
													)}
												</div>
											</div>
											<div className="lg:flex">
												<div className="lg:w-1/2">
													<p className="font-bold">
														Company Bio
													</p>
													<p className="text-gray-700">
														Customise your company
														bio so other companies
														know what it is you do
													</p>
												</div>
												<div className="lg:w-1/2">
													<Field
														id="aboutUs"
														name="aboutUs"
														placeholder="Tell visitors a little bit about your company. What makes you stand out?"
														type="text"
														as="textarea"
														maxLength="400"
														className="w-full h-32 p-2 border rounded"
														onBlur={(val) => {
															validateForm();
															setFieldValue(
																'aboutUs',
																val.target.value
															);
														}}
													/>
													<p
														className={
															values.aboutUs
																.length === 400
																? 'text-red-500 text-xs'
																: 'text-gray-400 text-xs'
														}
													>
														Characters remaining:{' '}
														{400 -
															values.aboutUs
																.length}
														/400
													</p>
													{errors.aboutUs && (
														<p className="text-error-500">
															{errors.aboutUs}
														</p>
													)}
												</div>
											</div>
											<div className="lg:flex">
												<div className="lg:w-1/2">
													<p className="font-bold">
														Company Logo
													</p>
												</div>
												<div className="lg:w-1/2">
													<input
														type="file"
														onChange={(e) => {
															onImageChange(
																e,
																validateForm
															);
															setFieldValue(
																'companyLogoUrl',
																URL.createObjectURL(
																	e.target
																		.files[0]
																)
															);
														}}
														className="w-full p-2 border border-gray-200 rounded flex-right"
													/>
													<div className="text-xs text-gray-400">
														Max 5mb, .jpg, .png
														1024px x 1024px
													</div>
													<div className="w-1/2 m-auto">
														<img
															alt=""
															src={image}
															className={`${
																imageUploading
																	? 'filter blur'
																	: ''
															}`}
														/>
													</div>
													{errors.companyLogoUrl && (
														<p className="text-error-500">
															{
																errors.companyLogoUrl
															}
														</p>
													)}
												</div>
											</div>
											<div className="lg:flex">
												<div className="ml-auto lg:w-1/2">
													<button
														className="px-4 py-2 font-bold text-white bg-blue-700 rounded hover:bg-blue-800"
														type="submit"
													>
														Save Profile
													</button>
												</div>
											</div>
										</div>
									</Form>
								);
							}}
						</Formik>
					)}
				</div>
			</div>
		</>
	);
};

export default CompanyProfile;
