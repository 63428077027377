import React from 'react';
import { lightFormat, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { trackEvent } from 'utils/ga';
import Icon from '@brighthr/component-icon';

import statusLabelMobile from '../utils/statusLabelMobile';
import statusLabelRegular from '../utils/statusLabelRegular';

const cellValue = 'hidden lg:flex flex w-1/6 mr-4 px-2 items-center';
const cellValueNarrow = 'hidden lg:flex flex w-1/12 mr-2 px-2 items-center';
const cellValueButtons =
	'hidden lg:flex flex px-2 mr-2 items-center justify-end';
const choosePathname = (id) => {
	const screenWidth = window.innerWidth;
	return screenWidth < 1024 ? '' : `/details/${id}`;
};

const CurrentAdvertRow = ({
	advert,
	handleView,
	activeId,
	expanded,
	mobileViewOn,
	handleArchive,
}) => (
	<div className="flex flex-col justify-between px-2 py-4 border-t lg:flex-row lg:p-3 odd:bg-gray-50">
		<Link
			key={advert.id}
			to={{
				pathname: choosePathname(advert.id),
				state: {
					advert,
				},
			}}
			onClick={() => {
				trackEvent('Admin Portal', 'click view current advert');
				handleView(advert.id);
			}}
			className="flex w-full"
		>
			<div className="flex justify-between w-full lg:w-auto">
				<span className="flex items-center mr-2 w-72 line-clamp-1">
					{advert.offer_promotional_text}
				</span>
				<div className="flex items-center">
					{statusLabelMobile(advert.approval_status)}
					<span className="lg:hidden">
						{activeId === advert.id && expanded ? (
							<Icon iconName="chevron-thick-up" size="sm" />
						) : (
							<Icon iconName="chevron-thick-down" size="sm" />
						)}
					</span>
				</div>
			</div>
			<span className={cellValue}>
				{lightFormat(parseISO(advert.createdAt), 'dd/MM/yyyy')}
			</span>
			<span className={cellValue}>
				{advert.offer_valid_until === '9999-12-31' ? (
					<span className="text-gray-400">N/A</span>
				) : (
					lightFormat(
						parseISO(advert.offer_valid_until),
						'dd/MM/yyyy'
					)
				)}
			</span>
			<span className={cellValueNarrow}>{advert.views}</span>
			<span className={cellValue}>
				{statusLabelRegular(advert.approval_status)}
			</span>
		</Link>

		<span className={cellValueButtons}>
			<Link
				to={{
					pathname: `/edit/${advert.id}`,
				}}
				onClick={() => {
					trackEvent('Admin Portal', 'click edit');
				}}
				className="flex items-center justify-between ml-6 font-bold text-blue-800"
			>
				<Icon
					iconName="pencil"
					className="fill-primary-700"
					size="sm"
				/>
				<span className="ml-2">Edit</span>
			</Link>
		</span>
		<span className={cellValueButtons}>
			{advert.approval_status === 'Approved' ? (
				<button
					type="button"
					className="flex items-center justify-between font-bold text-blue-800"
					onClick={() => {
						trackEvent('Admin Portal', 'click archive');
						handleArchive(advert.id);
					}}
				>
					<Icon
						iconName="inbox-empty"
						className="fill-primary-700"
						size="sm"
					/>
					<span className="ml-1.5">Archive</span>
				</button>
			) : (
				<span className="w-20" />
			)}
		</span>

		{/* mobile zone */}
		{mobileViewOn && activeId === advert.id && (
			<div className="flex flex-col mt-1 -mx-2 lg:hidden">
				<div className="flex justify-between p-2 bg-gray-100">
					<span className="text-gray-500">Submitted</span>
					<span className="">
						{lightFormat(parseISO(advert.createdAt), 'dd/MM/yyyy')}
					</span>
				</div>
				<div className="flex justify-between p-2">
					<span className="text-gray-500">Ends</span>
					<span className="">
						{advert.offer_valid_until === '9999-12-31' ? (
							<span className="text-gray-500">N/A</span>
						) : (
							lightFormat(
								parseISO(advert.offer_valid_until),
								'dd/MM/yyyy'
							)
						)}
					</span>
				</div>
				<div className="flex justify-between p-2">
					<span className="text-gray-500">Views</span>
					<span>{advert.views}</span>
				</div>
				<span className="flex py-4 pl-2">
					<Link
						to={{
							pathname: `/details/${advert.id}`,
						}}
						className="flex items-center justify-between font-bold text-blue-700"
					>
						<Icon
							iconName="visibility"
							size="sm"
							className="fill-primary-700"
						/>
						<span className="ml-2 mr-6">View advert</span>
					</Link>

					<Link
						to={{
							pathname: `/edit/${advert.id}`,
						}}
						onClick={() => {
							trackEvent('Admin Portal', 'click edit');
						}}
						className="flex items-center justify-between font-bold text-blue-700"
					>
						<Icon
							iconName="pencil"
							className="fill-primary-700"
							size="sm"
						/>
						<span className="ml-2 mr-6">Edit</span>
					</Link>
					{advert.approval_status === 'Approved' && (
						<button
							type="button"
							className="flex items-center justify-between font-bold text-blue-700"
							onClick={() => {
								handleArchive(advert.id);
							}}
						>
							<Icon
								iconName="inbox-empty"
								className="fill-primary-700"
								size="sm"
							/>
							<span className="ml-2">Archive</span>
						</button>
					)}
				</span>
			</div>
		)}
	</div>
);

export default CurrentAdvertRow;
