// /* eslint-disable no-useless-escape */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import submitAdvert from 'services/submitAdvert';
import updateAdvert from 'services/updateAdvert';
import getBrightCompanyDetails from 'services/getBrightCompanyDetails';
import getAdvert from 'services/getAdvert';
import recordInteraction from 'services/telemetry';
import getCompanyProfile from 'services/companyProfile/getCompanyProfile';
import getCategories from 'services/getCategories';
import getRegions from 'services/getRegions';
import getTags from 'services/getTags';
import Spinner from '@brighthr/component-spinner';
import { useAuthenticationContext } from 'context/AuthenticationContext';
import { useLocaleContext } from 'context/LocaleContext';
import { CompactHeader as Header } from 'pages/components/compactHeader';
import submitCompanyProfile from './submitCompanyProfile';
import validate from './utils/formikValidate';
import IntroductionBanner from '../components/IntroductionBanner';
import CancelEditLink from './components/CancelEditLink';
import CancelCreateLink from './components/CancelCreateLink';
import Title from './components/Title';
import Tags from './components/Tags';
import Website from './components/Website';
import AdvertImage from './components/AdvertImage';
import OfferType from './components/OfferType';
import RequirementTicks from './components/RequirementTicks';
import Audience from './components/Audience';
import PreviewButton from './components/PreviewButton';
import PreviewCard from './components/PreviewCard';
import PreviewPageView from './components/PreviewPageView';
import AdvertPeriod from './components/AdvertPeriod';
import AdvertCategory from './components/AdvertCategory';
import AdvertRegion from './components/AdvertRegion';
import CompanyLogo from './components/CompanyLogo';
import CompanyAboutUs from './components/CompanyAboutUs';
import SubmissionSuccessful from './components/SubmissionSuccessful';
import SubmissionFailed from './components/SubmissionFailed';

const AdvertSubmission = () => {
	const { advertId } = useParams();
	const { languageCode } = useLocaleContext();

	// hidden values
	const [brightCompanyDetails, setBrightCompanyDetails] = useState({});
	const [companyProfile, setCompanyProfile] = useState({});
	const { jwt } = useAuthenticationContext();

	// validation hints
	const [hasTitle, setHasTitle] = useState(false);
	const [hasDiscountType, setHasDiscountType] = useState(false);
	const [hasImage, setHasImage] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState({
		value: null,
		text: '',
	});
	const [selectedRegion, setSelectedRegion] = useState({
		value: null,
		text: '',
	});
	const [availableCategories, setAvailableCategories] = useState([]);
	const [availableRegions, setAvailableRegions] = useState([]);
	const [isPercentageDiscount, setIsPercentageDiscount] = useState(false);
	const [isNoOffer, setIsNoOffer] = useState(false);
	const [discountText, setDiscountText] = useState('');
	const [tags, setTags] = useState([]);

	const [agreesToTerms, setAgreesToTerms] = useState(false);
	const [previewAllowed, setPreviewAllowed] = useState(false);
	const [advertData, setAdvertData] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const [heroImage, setHeroImage] = useState(null);
	const [advertiserImage, setAdvertiserImage] = useState();
	const [isAlwaysVisible, setIsAlwaysVisible] = useState(true);
	const [visibleFrom, setVisibleFrom] = useState(null);
	const [visibleUntil, setVisibleUntil] = useState(null);
	const [uploadError, setUploadError] = useState(false);
	// preview
	const [currentStep, setCurrentStep] = useState('create');
	const [previewData, setPreviewData] = useState(null);
	const SUBMISSION_FORM = useRef(null);

	useEffect(() => {
		setPreviewAllowed(
			hasTitle === true &&
				(isNoOffer || hasDiscountType === true) &&
				hasImage === true &&
				selectedCategory.value !== null &&
				selectedCategory.value !== '-1' &&
				selectedRegion.value !== null &&
				selectedRegion.value !== '-1' &&
				agreesToTerms === true
		);
	}, [
		hasTitle,
		isNoOffer,
		hasDiscountType,
		hasImage,
		selectedCategory,
		selectedRegion,
		agreesToTerms,
	]);

	useEffect(() => {
		window.scrollTo(0, 0);
		Promise.all([
			getBrightCompanyDetails(jwt.company_id),
			getCompanyProfile(languageCode),
			advertId ? getAdvert(advertId, languageCode) : Promise.resolve({}),
			getCategories(languageCode),
			getRegions(languageCode),
		]).then(
			([
				companyDetailsResponse,
				companyProfileResponse,
				advertResponse,
				categories,
				regions,
			]) => {
				setBrightCompanyDetails(companyDetailsResponse);
				setAvailableCategories(categories);
				setAvailableRegions(regions);

				if (!advertId) {
					setCompanyProfile(companyProfileResponse);
					setAdvertiserImage({
						id: companyProfileResponse.companyLogoId,
						url: companyProfileResponse.companyLogoUrl,
					});
					setIsNoOffer(true);
				}
				if (advertId) {
					setHasImage(true);
					setHasTitle(true);
					setHasDiscountType(advertResponse.discount_text.length > 0);
					setSelectedCategory({
						value: advertResponse.offer_category_id,
						text: advertResponse.offer_category,
					});
					setSelectedRegion({
						value: advertResponse.offer_region_id,
						text: advertResponse.offer_region,
					});
					setDiscountText(advertResponse.discount_text);
					setIsPercentageDiscount(
						advertResponse.offer_is_percentage_discount
					);
					setHeroImage(advertResponse.hero_object);
					setAdvertiserImage({
						id: advertResponse.advertiser_logoFull.id,
						url: advertResponse.advertiser_logoFull.url,
					});
				}

				if (
					advertId &&
					advertResponse.discount_text === '' &&
					!advertResponse.offer_is_percentage_discount
				) {
					setIsNoOffer(true);
				}
				if (
					advertId &&
					advertResponse.offer_valid_from === '1900-01-01'
				) {
					setIsAlwaysVisible(true);
				}
				if (
					advertId &&
					advertResponse.offer_valid_from !== '1900-01-01'
				) {
					setIsAlwaysVisible(false);
					setVisibleFrom(advertResponse.offer_valid_from);
					setVisibleUntil(advertResponse.offer_valid_until);
				}
				if (advertId) {
					setAdvertData(advertResponse);
				}

				setIsLoading(false);
			}
		);

		if (languageCode === 'en-gb' || languageCode === 'en-ie') {
			getTags(languageCode).then((tagData) => {
				setTags(tagData);
			});
		}
	}, [advertId, jwt.company_id, languageCode]);

	return (
		<>
			{isLoading && (
				<div
					className="flex justify-center mt-24"
					data-testid="loading"
				>
					<Spinner
						size="base"
						className="stroke-black"
						ariaLabel="loading"
					/>
				</div>
			)}
			{!isLoading && (
				<div>
					<Header hideButtons />
					<div
						className={`${
							currentStep === 'create' ? '' : 'hidden'
						}`}
					>
						<IntroductionBanner />
						<Formik
							innerRef={SUBMISSION_FORM}
							initialValues={{
								email: `${jwt.email}`,
								advertiser_about_us: `${
									advertId
										? advertData.advertiser_about_us
										: companyProfile.aboutUs || ''
								}`,
								advertiser_name: `${
									brightCompanyDetails.name ?? ''
								}`,
								advertiser_logo: `${
									advertiserImage?.id ??
									'../placeholder_profile.png'
								}`,
								offer_promotional_text:
									advertData?.offer_promotional_text ?? '',
								offer_is_percentage_discount:
									isPercentageDiscount ?? true,
								discount_text: discountText ?? '',
								offer_promo_code:
									advertData?.offer_promo_code ?? '',
								offer_redemption_instructions:
									advertData?.offer_redemption_instructions ??
									'',
								offer_valid_from: visibleFrom ?? null,
								offer_valid_until: visibleUntil ?? null,
								offer_hero_image:
									heroImage?.url ?? '../placholder_hero.png',
								offer_is_b2b: advertData?.offer_is_b2b ?? false,
								offer_is_b2c: advertData?.offer_is_b2c ?? true,
								offer_redemption_url:
									advertData?.offer_redemption_url ?? '',
								company_profile_id: `${
									companyProfile.id ?? ''
								}`,
								offer_tag: advertData?.offer_tag || '',
							}}
							validate={validate}
							validateOnChange={false}
							onSubmit={async (values) => {
								try {
									values.offer_hero_image = heroImage.id;
									values.advertiser_logo = advertiserImage.id;
									values.offer_category =
										selectedCategory.value;
									values.offer_region = selectedRegion.value;
									if (
										languageCode === 'en-au' ||
										languageCode === 'en-nz' ||
										languageCode === 'en-ca'
									) {
										delete values.offer_tag;
									}
									values.bright_company_guid = jwt.company_id;

									if (values.offer_valid_from === null) {
										values.offer_valid_from = '1900-01-01';
									}
									if (values.offer_valid_until === null) {
										values.offer_valid_until = '9999-12-31';
									}
									if (advertId) {
										values.id = advertId;
										await updateAdvert(
											values,
											languageCode
										);
										recordInteraction(
											{
												action: `update success`,
											},
											languageCode
										);
									} else {
										await submitAdvert(
											values,
											languageCode
										);
									}

									if (!advertId) {
										await submitCompanyProfile(
											values,
											languageCode
										);
									}
									setCurrentStep('submitted');
									recordInteraction(
										{
											action: `submit success`,
										},
										languageCode
									);
								} catch (error) {
									recordInteraction(
										{
											action: `submit error`,
										},
										languageCode
									);
									setCurrentStep('error');
								}
							}}
						>
							{(formik) => {
								const {
									handleChange,
									setFieldValue,
									errors,
									touched,
									values,
									validateForm,
								} = formik;

								return (
									<Form
										id="new-ad-form"
										data-testid="submission-form"
									>
										<div className="items-start max-w-screen-xl mx-auto space-y-12 bg-white lg:p-12 lg:flex lg:flex-wrap">
											<div className="lg:w-2/3 lg:pr-16">
												<>
													<div className="w-44">
														{advertId ? (
															<CancelEditLink />
														) : (
															<CancelCreateLink />
														)}
													</div>
													<h1 className="mb-6 ml-2 text-4xl font-bold">
														{advertId
															? 'Edit Advert'
															: 'New Advert'}
													</h1>
												</>

												<h1
													className="pt-6 m-2 font-bold"
													id="company-profile-heading"
												>
													Company Profile
												</h1>
												<div className="p-4 m-2 bg-white border border-gray-300 rounded shadow-xl lg:m-0">
													<CompanyLogo
														errors={errors}
														brightCompanyDetails={
															brightCompanyDetails
														}
														uploadError={
															uploadError
														}
														setUploadError={
															setUploadError
														}
														advertiserImage={
															advertiserImage
														}
														setAdvertiserImage={
															setAdvertiserImage
														}
														setFieldValue={
															setFieldValue
														}
														validateForm={
															validateForm
														}
														advertId={advertId}
														companyProfile={
															companyProfile
														}
														locale={languageCode}
													/>
													<CompanyAboutUs
														values={values}
														errors={errors}
														setFieldValue={
															setFieldValue
														}
														companyProfile={
															companyProfile
														}
														setCompanyProfile={
															setCompanyProfile
														}
														validateForm={
															validateForm
														}
													/>
												</div>
												<h1
													className="mb-2 ml-2 font-bold pt-7"
													id="advert-details-heading"
												>
													Advert Details
												</h1>
												<div className="p-4 m-2 space-y-8 bg-white border border-gray-300 rounded shadow-xl lg:m-0">
													<Title
														setHasTitle={
															setHasTitle
														}
														validateForm={
															validateForm
														}
														setFieldValue={
															setFieldValue
														}
														values={values}
														errors={errors}
													/>
													<AdvertCategory
														availableCategories={
															availableCategories
														}
														selectedCategory={
															selectedCategory
														}
														setSelectedCategory={
															setSelectedCategory
														}
														handleChange={
															handleChange
														}
													/>
													<AdvertRegion
														availableRegions={
															availableRegions
														}
														selectedRegion={
															selectedRegion
														}
														setSelectedRegion={
															setSelectedRegion
														}
														handleChange={
															handleChange
														}
													/>
													<hr />
													<AdvertImage
														setUploadError={
															setUploadError
														}
														heroImage={heroImage}
														setHeroImage={
															setHeroImage
														}
														setHasImage={
															setHasImage
														}
														uploadError={
															uploadError
														}
														locale={languageCode}
													/>
													<hr />
													<Website
														errors={errors}
														touched={touched}
													/>
													{(languageCode ===
														'en-gb' ||
														languageCode ===
															'en-ie') &&
														tags.length > 0 && (
															<Tags
																tags={tags}
																offerTag={
																	values.offer_tag
																}
																setFieldValue={
																	setFieldValue
																}
															/>
														)}
												</div>

												<h1 className="pt-6 mb-2 ml-2 font-bold">
													Optional Offer and
													Redemption Instructions
												</h1>
												<div className="p-4 m-2 space-y-8 bg-white border border-gray-300 rounded shadow-xl lg:m-0">
													<OfferType
														values={values}
														errors={errors}
														validateForm={
															validateForm
														}
														setFieldValue={
															setFieldValue
														}
														isNoOffer={isNoOffer}
														setIsNoOffer={
															setIsNoOffer
														}
														isPercentageDiscount={
															isPercentageDiscount
														}
														setIsPercentageDiscount={
															setIsPercentageDiscount
														}
														setDiscountText={
															setDiscountText
														}
														setHasDiscountType={
															setHasDiscountType
														}
													/>
												</div>
												<h1
													className="pt-6 mb-2 ml-2 font-bold"
													id="advert-visibility-heading"
												>
													Visibility
												</h1>
												<div className="p-4 m-2 space-y-8 bg-white border border-gray-300 rounded shadow-xl lg:m-0">
													<AdvertPeriod
														isAlwaysVisible={
															isAlwaysVisible
														}
														setIsAlwaysVisible={
															setIsAlwaysVisible
														}
														visibleFrom={
															visibleFrom
														}
														setVisibleFrom={
															setVisibleFrom
														}
														visibleUntil={
															visibleUntil
														}
														setVisibleUntil={
															setVisibleUntil
														}
														setFieldValue={
															setFieldValue
														}
													/>
													<hr />
													<Audience
														values={values}
														setFieldValue={
															setFieldValue
														}
													/>
												</div>
											</div>

											<div className="p-4 space-y-6 bg-gray-100 rounded shadow-lg lg:w-1/3 lg:sticky lg:top-24">
												<PreviewCard
													values={values}
													advertiserImage={
														advertiserImage
													}
													brightCompanyDetails={
														brightCompanyDetails
													}
													heroImage={heroImage}
													selectedCategory={
														selectedCategory
													}
													isPercentageDiscount={
														isPercentageDiscount
													}
													discountText={discountText}
												/>
												<RequirementTicks
													advertData={advertData}
													errors={errors}
													values={values}
													validateForm={validateForm}
													selectedCategory={
														selectedCategory
													}
													hasImage={hasImage}
													heroImage={heroImage}
													selectedRegion={
														selectedRegion
													}
													hasDiscountType={
														hasDiscountType
													}
													isNoOffer={isNoOffer}
													agreesToTerms={
														agreesToTerms
													}
													setAgreesToTerms={
														setAgreesToTerms
													}
												/>
												<PreviewButton
													previewAllowed={
														previewAllowed
													}
													errors={errors}
													SUBMISSION_FORM={
														SUBMISSION_FORM
													}
													brightCompanyDetails={
														brightCompanyDetails
													}
													selectedCategory={
														selectedCategory
													}
													selectedRegion={
														selectedRegion
													}
													heroImage={heroImage}
													advertiserImage={
														advertiserImage
													}
													advertData={advertData}
													setPreviewData={
														setPreviewData
													}
													setCurrentStep={
														setCurrentStep
													}
												/>
											</div>
										</div>
									</Form>
								);
							}}
						</Formik>
					</div>

					{currentStep === 'preview' && (
						<PreviewPageView
							advertId={advertId}
							setCurrentStep={setCurrentStep}
							previewData={previewData}
							SUBMISSION_FORM={SUBMISSION_FORM}
						/>
					)}

					{currentStep === 'submit' && (
						<div className="flex justify-center mt-24">
							<Spinner
								size="base"
								className="stroke-black"
								ariaLabel="loading"
							/>
						</div>
					)}

					{currentStep === 'submitted' && (
						<SubmissionSuccessful
							advert={previewData}
							isEditMode={Boolean(advertId)}
						/>
					)}

					{currentStep === 'error' && (
						<SubmissionFailed setCurrentStep={setCurrentStep} />
					)}
				</div>
			)}
		</>
	);
};

export default AdvertSubmission;
// /* eslint-enable no-useless-escape */
