const validate = (values) => {
	const errors = {};
	if (
		values.offer_is_percentage_discount &&
		Number(values.discount_text) > 100
	) {
		errors.discount_text = 'Discount must be 100% or less';
	}
	if (values.offer_is_percentage_discount && values.discount_text === '0') {
		errors.discount_text = 'Please enter a positive value';
	}
	if (
		values.offer_is_percentage_discount &&
		values.discount_text.includes('.') &&
		values.discount_text.split('.')[1].length > 2
	) {
		errors.discount_text = 'Two decimal places only';
	}
	if (values.offer_promotional_text === '') {
		errors.offer_promotional_text = 'A title is required';
	}
	if (values.advertiser_about_us === '') {
		errors.advertiser_about_us = 'This section is required';
	}
	if (values.advertiser_logo === '') {
		errors.advertiser_logo = 'Please upload a company logo';
	}
	return errors;
};

export default validate;
