import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '@brighthr/component-spinner';

import getAdvert from 'services/getAdvert';
import recordInteraction from 'services/telemetry';
import { useLocaleContext } from 'context/LocaleContext';
import DetailsComponent from 'pages/components/details';
import { CompactHeader as Header } from 'pages/components/compactHeader';
import NotFound from 'pages/not_found';

const Details = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [details, setDetails] = useState();
	const { id } = useParams();

	const { languageCode } = useLocaleContext();

	useEffect(() => {
		window.scrollTo(0, 0);
		getAdvert(id, languageCode).then((apiResponse) => {
			setDetails(apiResponse);
			setIsLoading(false);
			recordInteraction({ advert: apiResponse }, languageCode);
		});
	}, [id, languageCode]);

	if (!isLoading && !details) {
		return <NotFound />;
	}
	return (
		<>
			<Header />
			{isLoading && (
				<div className="flex justify-center mt-24">
					<Spinner
						size="base"
						className="stroke-black"
						ariaLabel="loading"
					/>
				</div>
			)}
			{!isLoading && <DetailsComponent advert={details} />}
		</>
	);
};

export default Details;
