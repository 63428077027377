const selectAdvertisingPolicy = (currentLocale) => {
	if (
		currentLocale.countryCode === 'au' ||
		currentLocale.countryCode === 'nz'
	)
		return '/advertising_policy_au_nz';
	if (currentLocale.countryCode === 'ca') {
		return '/advertising_policy_ca';
	}
	return '/advertising_policy';
};

export default selectAdvertisingPolicy;
