import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import recordInteraction from 'services/telemetry';
import isAdmin from 'utils/isAdmin';
import { trackEvent } from 'utils/ga';
import getToken from 'utils/getToken';
import getBrightCompanyDetails from 'services/getBrightCompanyDetails';
import getCompanyProfile from 'services/companyProfile/getCompanyProfile';
import { useLocaleContext } from 'context/LocaleContext';
import { useViewportContext } from 'context/ViewportContext';
import Icon from '@brighthr/component-icon';
import { BrightLogo } from './BrightLogo';
import { ContextMenu } from '../contextMenu';
import { CompactLocaleSelector as LocaleSelector } from '../compactLocaleSelector';
// import Banner from '../banner';

export type CreateAdvertLinkType = React.HTMLAttributes<HTMLLinkElement> & {
	className?: string;
	locale?: string;
};

export type CompanyDetails = {
	id: string;
	tenant?: string;
	name?: string;
	leadType?: string;
	industry?: string;
};

const CreateAdvertLink = ({ className, locale }: CreateAdvertLinkType) => (
	<a
		href="/submission"
		className={cn(
			className,
			'flex justify-center px-1 sm:px-8 py-2.5 mr-2 font-bold text-white bg-blue-700 rounded border-solid border-2 border-transparent',
			'hover:bg-blue-600',
			'focus:border-solid focus:border-2 focus:border-white whitespace-nowrap'
		)}
		onClick={() => {
			recordInteraction(
				{
					action: 'create advert',
				},
				locale
			);
			trackEvent('Header', 'click new advert');
		}}
	>
		Create Advert
	</a>
);

const Logout = () => (
	<div className="flex border-2 border-gray-300 py-1.5 px-3 rounded-full focus-within:border-solid focus-within:border-2 focus-within:border-blue-700 ml-12">
		<a
			href="/logout"
			className="flex items-center text-blue-700 outline-none"
		>
			<span className="flex items-center mr-2 aria-hidden">
				<Icon
					iconName="power"
					size="sm"
					className="text-blue-400 fill-current"
				/>
			</span>
			<span className="text-sm">Log out</span>
		</a>
	</div>
);

export const CompactHeader = (props) => {
	const { isLargeViewport } = useViewportContext();
	const [brightCompanyDetails, setBrightCompanyDetails] =
		useState<CompanyDetails>();

	const [companyLogoUrl, setCompanyLogoUrl] = useState<string>('');
	const { languageCode } = useLocaleContext();

	const jwt = getToken();

	const hasRoles = Object.prototype.hasOwnProperty.call(jwt, 'role');

	useEffect(() => {
		getBrightCompanyDetails(jwt?.company_id).then((company) => {
			setBrightCompanyDetails(company);
		});
	}, [jwt?.company_id]);

	useEffect(() => {
		Promise.all([getCompanyProfile(languageCode)])
			.then(([companyData]) => {
				setCompanyLogoUrl(companyData.companyLogoUrl);
			})
			.catch((err) => {
				console.log('err', err);
			});
	}, [languageCode]);

	return (
		<div className="sticky top-0 z-60">
			{/* <Banner /> */}
			<div className="flex justify-center bg-white bg-opacity-90">
				<div className="w-full max-w-screen-2xl">
					{isLargeViewport ? (
						<div
							className={cn(
								'flex items-center justify-between px-12 py-2 bg-white space-between bg-opacity-90'
							)}
							{...props}
						>
							<div className={cn('flex items-center')}>
								<a
									href="/groups"
									aria-label="Go to marketplace"
								>
									<BrightLogo className="my-4" />
								</a>
								{brightCompanyDetails && (
									<div className="mx-8">
										{brightCompanyDetails?.name}
									</div>
								)}
							</div>
							<div className="flex items-center">
								{isAdmin() && hasRoles && (
									<CreateAdvertLink
										className="grow"
										locale={languageCode}
									/>
								)}
								<LocaleSelector className="grow-0" />
								{hasRoles ? (
									<ContextMenu
										isAdmin={isAdmin()}
										className="ml-12"
										company={brightCompanyDetails?.name}
										companyLogoUrl={companyLogoUrl}
									/>
								) : (
									<Logout />
								)}
							</div>
						</div>
					) : (
						<div className="sticky top-0 flex flex-col w-full border-b bg-opacity-90 h-22">
							<div
								className={cn(
									'flex justify-between items-center bg-white py-2 px-4  max-w-screen-2xl'
								)}
								{...props}
							>
								<a
									href="/groups"
									aria-label="Go to marketplace"
								>
									<BrightLogo className="my-2" />
								</a>
								{brightCompanyDetails ? (
									<ContextMenu
										isAdmin={isAdmin()}
										company={brightCompanyDetails?.name}
										companyLogoUrl={companyLogoUrl}
									/>
								) : (
									<Logout />
								)}
							</div>
							<div className="flex items-center justify-between px-4 py-2 bg-gray-100 bg-opacity-90">
								{isAdmin() && (
									<CreateAdvertLink
										className="flex-1"
										locale={languageCode}
									/>
								)}
								<LocaleSelector className="grow-0" />
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CompactHeader;
