import './styles/App.css';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { initialise as initGA } from 'utils/ga';
import gaPageView from 'utils/ga/pageview';
import { AuthenticationProvider } from 'context/AuthenticationContext';
import { LocaleProvider } from 'context/LocaleContext';
import { ViewportProvider } from 'context/ViewportContext';
import { initTrackJS } from './utils/initTrackJS';

import Routes from './routing';
import useAuthentication from './hooks/useAuthentication';
import Landing from './pages/landing';
import useUserpilot from './hooks/useUserpilot';

initGA();
initTrackJS();

function App() {
	const { isAuthenticated } = useAuthentication();
	gaPageView();

	const getChildren = () => {
		useUserpilot();
		if (isAuthenticated) {
			return (
				<BrowserRouter>
					<LocaleProvider>
						<ViewportProvider>
							<AuthenticationProvider>
								<Routes />
							</AuthenticationProvider>
						</ViewportProvider>
					</LocaleProvider>
				</BrowserRouter>
			);
		}

		if (window.location.pathname.match(/\/(en|au|nz|ie|ca)*(?:\/|$)/i)) {
			return (
				<BrowserRouter>
					<LocaleProvider>
						<Landing />
					</LocaleProvider>
				</BrowserRouter>
			);
		}

		return null;
	};

	return getChildren();
}

export default App;
