import React from 'react';

const Audience = ({ values, setFieldValue }) => (
	<div className="space-y-2">
		<div>
			Audience
			<span
				className="pl-2 text-xs text-gray-400"
				id="advert-audience-heading"
			>
				Who would you like to be able to see this advert?
			</span>
		</div>
		<div className="flex space-x-2">
			<button
				id="advert-b2c-button"
				type="button"
				className={`w-1/2 border rounded p-2 cursor-pointer ${
					!values.offer_is_b2b ? 'bg-blue-700 text-white' : ''
				}`}
				onClick={() => {
					setFieldValue('offer_is_b2b', false);
					setFieldValue('offer_is_b2c', true);
				}}
			>
				People (B2C)
			</button>
			<button
				type="button"
				id="advert-b2b-button"
				className={`w-1/2 border rounded p-2 cursor-pointer ${
					values.offer_is_b2b ? 'bg-blue-700 text-white' : ''
				}`}
				onClick={() => {
					setFieldValue('offer_is_b2b', true);
					setFieldValue('offer_is_b2c', false);
				}}
			>
				Businesses (B2B)
			</button>
		</div>
	</div>
);

export default Audience;
