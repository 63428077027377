import React from 'react';
import { trackEvent } from 'utils/ga';

const AudienceSelector = ({
	id,
	name,
	defaultText,
	defaultSelection,
	setAudienceFilter,
	setIsLoading,
	onChange = null,
}) => {
	const getDefaultOption = () => {
		switch (defaultSelection) {
			case 'b2b':
				return 'b2b';
			case 'b2c':
				return 'b2c';
			default:
				return 'default';
		}
	};

	return (
		<React.Fragment>
			<select
				id={id}
				name={name}
				className="px-2 py-3 border rounded"
				defaultValue={getDefaultOption()}
				onChange={
					onChange ||
					((e) => {
						setIsLoading(true);
						setAudienceFilter(e.target.value);
						trackEvent('marketplace', 'click audience filter');
					})
				}
			>
				<option key="default" value="0">
					{defaultText}
				</option>
				<option key="b2b" value="b2b">
					Business to business
				</option>
				<option key="b2c" value="b2c">
					Business to consumer
				</option>
			</select>
		</React.Fragment>
	);
};
export default AudienceSelector;
