import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import config from 'utils/config';

const { GA_TRACKING_ID, GA4_MEASUREMENT_ID } = config();

export const trackEvent = (
	category,
	action,
	label,
	nonInteraction = false,
	callback = () => {}
) => {
	ReactGA.event({
		category,
		action,
		...(label && { label }),
		...(nonInteraction && { nonInteraction }),
	});

	if (ReactGA4.isInitialized) {
		const ga4Params = {};
		const actionString = `${action}${label ? ` ${label}` : ''}`;

		const getStrSection = (i) =>
			actionString.substring((i - 1) * 100, i * 100);

		for (let i = 1; i <= Math.ceil(actionString.length / 100); i++) {
			ga4Params[`action${i === 1 ? '' : i}`] = getStrSection(i);
		}

		if (nonInteraction) {
			ga4Params.nonInteraction = true;
		}

		ReactGA4.event(category, ga4Params);
	}
	callback();
};

export const initialise = () => {
	if (GA_TRACKING_ID) {
		ReactGA.initialize(GA_TRACKING_ID);
	} else {
		ReactGA.initialize('foo', { testMode: true });
	}

	if (GA4_MEASUREMENT_ID) {
		ReactGA4.initialize(GA4_MEASUREMENT_ID);
	} else {
		ReactGA4.initialize('foo', { testMode: true });
	}
};

export default initialise;
