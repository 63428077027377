import React from 'react';
import { useLocaleContext } from 'context/LocaleContext';
import RegionSelector from '../../../components/region_selector';

const AdvertRegion = ({
	availableRegions,
	selectedRegion,
	setSelectedRegion,
	handleChange,
}) => {
	const { currentLocale } = useLocaleContext();

	return (
		<div className="space-y-2">
			<p id="advert-region-heading">{`Advert ${
				currentLocale.countryCode === 'ca' ? 'Province' : 'Region'
			}`}</p>
			<RegionSelector
				id="offer_region"
				name="offer_region"
				defaultText={`Select a ${
					currentLocale.countryCode === 'ca' ? 'Province' : 'Region'
				}`}
				availableRegions={availableRegions}
				defaultSelection={selectedRegion.value}
				className="border rounded"
				onChange={(e) => {
					const index = e.nativeEvent.target.selectedIndex;
					setSelectedRegion({
						value: e.target.value,
						text: e.nativeEvent.target[index].text,
					});
					handleChange(e);
				}}
			/>
		</div>
	);
};

export default AdvertRegion;
