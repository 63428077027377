/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */

import isAdmin from 'utils/isAdmin';
import getToken from 'utils/getToken';
import { useEffect } from 'react';

export default function useHotJar() {
	useEffect(() => {
		const jwt = getToken();
		const userId = jwt.sub;
		if (window.location.hostname === 'exchange.brighthr.com') {
			((h, o, t, j, a, r) => {
				h.hj =
					h.hj ||
					function () {
						(h.hj.q = h.hj.q || []).push(arguments);
					};
				h._hjSettings = {
					hjid: 2582082,
					hjsv: 6,
				};
				a = o.getElementsByTagName('head')[0];
				r = o.createElement('script');
				r.async = 1;
				r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
				a.appendChild(r);
			})(
				window,
				document,
				'https://static.hotjar.com/c/hotjar-',
				'.js?sv='
			);

			if (userId) {
				window.hj('identify', userId, {
					userType: isAdmin() ? 'admin' : 'employee',
				});
			}
		}
	}, []);
}

/* eslint-enable prefer-destructuring */
/* eslint-enable prefer-rest-params */
/* eslint-enable func-names */
