import React from 'react';
import { Link } from 'react-router-dom';
import { useLocaleContext } from 'context/LocaleContext';
import Requirement from '../Requirement';
import selectAdvertisingPolicy from '../../utils/selectAdvertisingPolicy/index';

const RequirementTicks = ({
	advertData,
	errors,
	values,
	validateForm,
	selectedCategory,
	hasImage,
	heroImage,
	selectedRegion,
	hasDiscountType,
	isNoOffer,
	agreesToTerms,
	setAgreesToTerms,
}) => {
	const { currentLocale } = useLocaleContext();

	return (
		<div>
			<ul className="text-gray-600">
				<Requirement
					isSatisfied={
						(advertData?.offer_promotional_text &&
							!errors.offer_promotional_text) ||
						values.offer_promotional_text !== ''
					}
					requirementText="a Title"
				/>
				<Requirement
					isSatisfied={
						Boolean(advertData?.offer_category) ||
						(selectedCategory.value !== null &&
							selectedCategory.value !== '-1')
					}
					requirementText="a Category"
				/>
				<Requirement
					isSatisfied={
						Boolean(advertData?.offer_region) ||
						(selectedRegion.value !== null &&
							selectedRegion.value !== '-1')
					}
					requirementText={`a ${
						currentLocale.countryCode === 'ca'
							? 'Province'
							: 'Region'
					}`}
				/>
				<Requirement
					isSatisfied={
						heroImage ||
						Boolean(values.offer_hero_image.url) ||
						hasImage
					}
					requirementText="an Image"
				/>
				{!isNoOffer && (
					<Requirement
						isSatisfied={
							(isNoOffer ||
								hasDiscountType ||
								advertData?.offer_is_percentage_discount) &&
							!errors.discount_text
						}
						requirementText="a Discount Type"
					/>
				)}
			</ul>
			<div className="pt-2">
				<input
					name="read_advertising_policy"
					type="checkbox"
					checked={agreesToTerms}
					onChange={(e) => {
						setAgreesToTerms(e.target.checked);
						validateForm();
					}}
				/>
				<span className="pl-2">
					I agree to the{' '}
					<Link
						className="text-blue-600 underline"
						to={selectAdvertisingPolicy(currentLocale)}
						target="_blank"
					>
						advertising policy
					</Link>
				</span>
			</div>
		</div>
	);
};

export default RequirementTicks;
