import getToken from '../getToken';

export default (environment = 'sandbox') => {
	const token = getToken();
	const locale = token?.locale || 'en-gb';

	const getEnv = (config) => {
		const country = locale.slice(3);
		const firstLevel =
			config[country.toLowerCase()] ||
			config[environment] ||
			config.default;
		return firstLevel[environment] || firstLevel.default || firstLevel;
	};

	return {
		EXCHANGE_API: getEnv({
			default: 'http://localhost:7071/v1/exchange',
			prod: 'https://api.brighthr.com/v1/exchange',
			sandbox: 'https://sandbox-api.brighthr.com/v1/exchange',
			qa: 'https://qa01-api.brighthr.com/v1/exchange',
		}),

		COMPANY_API: getEnv({
			default: 'https://sandbox-api.brighthr.com/v1/company',
			prod: 'https://api.brighthr.com/v1/company',
		}),

		BRIGHT_HR_LINK: getEnv({
			default: 'https://sandbox-app.brighthr.com',
			prod: 'https://app.brighthr.com',
			dev: 'http://localhost:9000',
		}),

		AUTHORISATION_SERVER_URL: getEnv({
			prod: 'https://login.brighthr.com',
			default: 'https://sandbox-login.brighthr.com',
		}),

		GA_TRACKING_ID: getEnv({
			prod: 'UA-67739736-16',
			qa: 'UA-67739736-17',
			sandbox: 'UA-67739736-17',
			default: '',
		}),
		GA4_MEASUREMENT_ID: getEnv({
			prod: 'G-PG1F5JET3K',
			qa: 'G-3YGBRBPY9L',
			sandbox: 'G-3YGBRBPY9L',
			default: '',
		}),
		USERPILOT_ENABLED: getEnv({
			prod: true,
			default: true,
		}),

		ELASTIC_APM_URL:
			'https://011c8969acb54bd4a5f982b25fd71461.apm.eu-west-1.aws.cloud.es.io:443',
	};
};
