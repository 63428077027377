import http from 'utils/http';
import config from 'utils/config';
import mapAdvert from 'utils/mappers/advertMapper';
import { Group } from '../../domain/Groups';

interface Params {
	groupId?: string;
	categoryId?: string;
	regionId?: string;
	audience?: string;
	pageNumber?: number;
	pageSize?: number;
	searchTerm?: string;
	locale?: string;
}

const getSpotlight = async ({
	audience,
	locale,
	groupId,
	pageNumber = 1,
	categoryId,
	regionId,
	searchTerm,
	pageSize,
}: Params): Promise<Group> => {
	const { EXCHANGE_API } = config();

	const uri = `${EXCHANGE_API}/${locale}/group/${groupId}/${categoryId}/${regionId}/${audience}/${pageNumber}/${pageSize}/${searchTerm}`;

	const { data } = await http.get(uri);
	const { data: groupData, ...rest } = data;
	const { adverts } = groupData;

	const mappedAdverts = adverts.map((advert) => mapAdvert(advert));
	return { ...groupData, adverts: mappedAdverts, ...rest };
};

export default getSpotlight;
