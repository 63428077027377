import React, { useContext, createContext, useState } from 'react';
import getToken from 'utils/getToken';

const AuthenticationContext = createContext();
const useAuthenticationContext = () => {
	const context = useContext(AuthenticationContext);
	if (!context)
		throw new Error(
			'Authentication context needs to be wrapped in provider'
		);
	return context;
};

const AuthenticationProvider = ({ children }) => {
	const [jwt] = useState(getToken());

	return (
		<AuthenticationContext.Provider value={{ jwt }}>
			{children}
		</AuthenticationContext.Provider>
	);
};

export { AuthenticationProvider, useAuthenticationContext };
