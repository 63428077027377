import React from 'react';

const statusLabelRegular = (status) => {
	if (status === 'Approved')
		return (
			<p className="flex items-center justify-center text-xs tracking-wider text-green-800 py-1 px-3 bg-green-50 rounded-2xl">
				APPROVED
			</p>
		);
	if (status === 'Pending')
		return (
			<p className="flex items-center justify-center text-xs tracking-wider text-yellow-800 py-1 px-3 bg-yellow-50 rounded-2xl">
				PENDING
			</p>
		);
	if (status === 'Rejected')
		return (
			<p className="flex items-center justify-center text-xs tracking-wider text-red-800 py-1 px-3 bg-red-50 rounded-2xl">
				DECLINED
			</p>
		);
};

export default statusLabelRegular;
