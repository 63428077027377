import React from 'react';
import UTCDatePicker from '../../utc_datepicker';

const AdvertPeriod = ({
	isAlwaysVisible,
	setIsAlwaysVisible,
	visibleFrom,
	setVisibleFrom,
	visibleUntil,
	setVisibleUntil,
	setFieldValue,
}) => (
	<div className="space-y-2">
		<p id="advert-period-heading" className="flex items-center">
			Advert Period
		</p>

		<div className="flex space-x-2">
			<button
				type="button"
				className={`w-1/2 border rounded p-2 cursor-pointer ${
					isAlwaysVisible ? 'bg-blue-700 text-white' : ''
				}`}
				onClick={() => {
					setIsAlwaysVisible(true);
					setVisibleFrom(null);
					setVisibleUntil(null);
					setFieldValue('offer_valid_from', null);
					setFieldValue('offer_valid_until', null);
				}}
			>
				Always
			</button>
			<button
				type="button"
				data-testid="limited-period-selected"
				className={`w-1/2 border rounded p-2 cursor-pointer ${
					isAlwaysVisible ? '' : 'bg-blue-700 text-white'
				}`}
				onClick={() => {
					setIsAlwaysVisible(false);
				}}
			>
				Limited
			</button>
		</div>
		{!isAlwaysVisible && (
			<div className="flex flex-row md:flex-col">
				<div className="flex flex-col grid-cols-2 mt-3 mr-8 md:flex-row md:mt-0 md:mr-0">
					<div
						className="w-1/2 mb-6 md:mb-0"
						id="advert-visiblefrom-heading"
					>
						From
					</div>
					<div className="w-1/2" id="advert-visibleuntil-heading">
						To
					</div>
				</div>
				<div
					data-testid="date-selectors"
					className="flex flex-col grid-cols-2 mt-3 md:flex-row md:mt-0"
				>
					<div className="z-20 w-1/2 mb-2 md:mb-0">
						<UTCDatePicker
							selected={visibleFrom}
							maxDate={visibleUntil}
							onChange={(date) => {
								setVisibleFrom(date);
								setFieldValue('offer_valid_from', date);
							}}
						/>
					</div>
					<UTCDatePicker
						selected={visibleUntil}
						minDate={visibleFrom}
						onChange={(date) => {
							setVisibleUntil(date);
							setFieldValue('offer_valid_until', date);
						}}
					/>
				</div>
			</div>
		)}
	</div>
);

export default AdvertPeriod;
