import http from 'utils/http';
import config from 'utils/config';

const getAdvert = (id, locale) => {
	const { EXCHANGE_API } = config();
	const uri = `${EXCHANGE_API}/${locale}/details/${id}`;

	return http.get(uri).then(({ data }) => {
		if (data) {
			return {
				id,
				advertiser_name: data.advertiserName,
				hero: data.offerHeroImage?.url ?? '../placeholder_hero.png',
				hero_object: data.offerHeroImage,
				advertiser_about_us: data.advertiserAboutUs,
				advertiser_logo:
					data.advertiserLogo?.url ?? '../placeholder_profile.png',
				advertiser_logoFull: data.advertiserLogo,
				offer_category: data.offerCategory.name,
				offer_category_id: data.offerCategory.id,
				offer_region: data.offerRegion.name,
				offer_region_id: data.offerRegion.id,
				offer_promo_code: data.offerPromoCode,
				offer_promotional_text: data.offerPromotionalText,
				offer_redemption_instructions: data.offerRedemptionInstructions,
				offer_spotlight: data.offerSpotlight,
				offer_valid_from: data.offerValidFrom,
				offer_valid_until: data.offerValidUntil,
				offer_is_percentage_discount: data.isPercentageDiscount,
				offer_is_b2b: data.offerIsB2b,
				offer_is_b2c: data.offerIsB2c,
				offer_tag: data.offerTag,
				discount_text: data.discountText,
				offer_redemption_url:
					data.offerRedemptionUrl === ''
						? null
						: data.offerRedemptionUrl,
				bright_user_company_guid: data.brightUserCompanyId,
				approval_status: data.approvalStatus,
			};
		}
		return null;
	});
};

export default getAdvert;
