import GetToken from '../getToken';

const IsAdmin = () => {
	const decodedToken = GetToken();
	if (!decodedToken?.role) {
		return false;
	}
	return (
		decodedToken.role.includes('HrAdministrator') ||
		decodedToken.role.includes('CompanyPointOfContact')
	);
};

export default IsAdmin;
